import React, { useState, useEffect } from 'react'
import PPBox from "components/PPBox";
import { TextField } from '@material-ui/core';
import PPInput from "components/PPInput";
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import moment from 'moment'
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import PPButton from "components/PPButton";
import Autocomplete from '@mui/material/Autocomplete';
import PPTypography from "components/PPTypography";
import Divider from '@mui/material/Divider';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DocumentationFilesList from "./DocumentationFilesList";
import { useTranslation } from 'react-i18next';
import TemplatesListing from './TemplatesListing';

function DocumentationEditor({ id, patientIds, eventId, documentationDate, handleSubmitCallback, handleCloseDialog, handleOpenSpinnerPanel }) {
    const { t } = useTranslation();
    const [dialogTemplatesOpen, setDialogTemplatesOpen] = useState(false);
    const [pageState, setPageState] = useState({
        documentationId: id,
        eventId: eventId ? eventId : '',
        patientIds: patientIds,
        documentationDate: documentationDate ? documentationDate : moment(new Date()).format("yyyy-MM-DD HH:mm"),
        notes: '',
        fileNames: [],
        friendlyFileNames: [],
        filesToUpload: [],
        templates: [],
    });

    const handleFileInputChange = (e) => {
        e.preventDefault();
        setPageState(x => ({ ...x, filesToUpload: e.target.files ? e.target.files : [] }));
    };

    const handleNotesChange = (html) => {
        setPageState(x => ({ ...x, notes: html ? html : '' }));
    };

    const handleTemplateOnChange = (e, option) => {
        if (option != null) {
            var template = pageState.templates.find(x => x.value === option.value);
            setPageState(x => ({ ...x, notes: template.content }));
        }
        else {
            setPageState(x => ({ ...x, notes: '' }));
        }
    };

    const handleOpenTemplateEditor = (e) => {
        setDialogTemplatesOpen(true);
    };

    // const handlePasteFromClipboard = async () => {
    //     if ('clipboard' in navigator) {
    //         try {
    //             const clipboardContent = await navigator.clipboard.readText();
    //             setPageState(x => ({ ...x, notes: clipboardContent ? clipboardContent : '' }));
    //         } catch (error) {
    //             console.error('Failed to read clipboard content:', error);
    //         }
    //     } else {
    //         console.warn('Clipboard API not supported. Fallback may not work in some browsers.');
    //         document.execCommand('paste');
    //     }
    // };

    const handleDocumentationSubmit = (e) => {
        e.preventDefault();
        if (pageState.notes.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            return
        }
        handleOpenSpinnerPanel(true);
        patientIds.forEach((p) => {
            var serviceUrl = '';
            const submitData = new FormData();
            submitData.append('eventId', pageState.eventId);
            submitData.append('patientId', p);
            submitData.append('documentationDate', pageState.documentationDate);
            submitData.append('notes', pageState.notes ? pageState.notes : "");
            for (let i = 0; i < pageState.filesToUpload.length; i++) {
                submitData.append('files', pageState.filesToUpload[i]);
            }
            if (pageState.documentationId !== null && pageState.documentationId !== '') {
                submitData.append('id', pageState.documentationId);
                serviceUrl = appEndPoints.documentationsEndpoint.replace("{hostname}", appEndPoints.hostname);
                fetch(serviceUrl, getRequestOptions('PUT', submitData))
                    .then(resp => resp.text())
                    .then(resp => {
                        if (typeof handleSubmitCallback === 'function' && handleSubmitCallback !== undefined) {
                            handleSubmitCallback();
                        }
                        if (typeof handleCloseDialog === 'function' && handleCloseDialog !== undefined) {
                            handleCloseDialog();
                        }
                        handleOpenSpinnerPanel(false);
                    })
                    .catch(error => { handleOpenSpinnerPanel(false); console.log('error', error); })
            }
            else {
                serviceUrl = appEndPoints.documentationsEndpoint.replace("{hostname}", appEndPoints.hostname);
                fetch(serviceUrl, getRequestOptions('POST', submitData))
                    .then(resp => resp.text())
                    .then(resp => {
                        if (typeof handleSubmitCallback === 'function' && handleSubmitCallback !== undefined) {
                            handleSubmitCallback();
                        }
                        if (typeof handleCloseDialog === 'function' && handleCloseDialog !== undefined) {
                            handleCloseDialog();
                        }
                        handleOpenSpinnerPanel(false);
                    })
                    .catch(error => { handleOpenSpinnerPanel(false); console.log('error', error); })
            }
        });
    }

    function fetchTemplates() {
        handleOpenSpinnerPanel(true);
        fetch(appEndPoints.templateEndpoint.replace("{hostname}", appEndPoints.hostname), getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                var elements = [];
                elements.push({
                    value: "",
                    label: "",
                    content: "",
                });
                for (var i = 0; i < resp.length; i++) {
                    elements.push({
                        value: resp[i].id,
                        label: resp[i].name,
                        content: resp[i].content,
                    });
                }
                setPageState(x => ({
                    ...x, templates: elements
                }));
                handleOpenSpinnerPanel(false);
            })
            .catch(error => { handleOpenSpinnerPanel(false); console.log('error', error); })
    }

    function fetchDocumentation() {
        if (pageState.documentationId !== null && pageState.documentationId !== '' && pageState.documentationId !== undefined) {
            handleOpenSpinnerPanel(true);
            fetch(appEndPoints.documentationsEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + pageState.documentationId, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    setPageState(x => ({
                        ...x, documentationId: resp.id, eventId: resp.eventId, patientId: resp.patientId,
                        documentationDate: resp.documentationDate, notes: resp.notes,
                        fileNames: resp.fileNames, friendlyFileNames: resp.friendlyFileNames
                    }));
                    handleOpenSpinnerPanel(false);
                })
                .catch(error => { handleOpenSpinnerPanel(false); console.log('error', error); })
        }
        else if (pageState.eventId !== '' && pageState.eventId !== undefined && pageState.patientId !== '' && pageState.patientId !== undefined) {
            handleOpenSpinnerPanel(true);
            fetch(appEndPoints.documentationsEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + pageState.eventId + "/" + pageState.patientId + "/" + pageState.documentationDate, getRequestOptions())
                .then(resp => {
                    if (resp.ok && resp.status === 204) {
                        return null;
                    }
                    return resp.json();
                })
                .then(resp => {
                    if (resp !== null) {
                        setPageState(x => ({
                            ...x, documentationId: resp.id, eventId: resp.eventId, patientId: resp.patientId,
                            documentationDate: resp.documentationDate, notes: resp.notes,
                            fileNames: resp.fileNames, friendlyFileNames: resp.friendlyFileNames
                        }));
                        handleOpenSpinnerPanel(false);
                    }
                })
                .catch(error => { handleOpenSpinnerPanel(false); console.log('error', error); })
        }
        else {
            //handlePasteFromClipboard();
        }
    }

    const handleCloseTemplatesDialog = () => {
        setDialogTemplatesOpen(false);
    };

    const handleRefreshTemplatesListing = () => {
        fetchTemplates();
        setDialogTemplatesOpen(false);
    };

    useEffect(() => {
        fetchTemplates();
        fetchDocumentation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PPBox>
            {
                dialogTemplatesOpen ? <TemplatesListing open={dialogTemplatesOpen} handleCloseDialog={handleCloseTemplatesDialog} handleRefreshListing={handleRefreshTemplatesListing} /> :
                    <div>
                        <div style={{ paddingBottom: "10px" }}>
                            <PPTypography variant="caption" color="text">
                                {t('writeDocumentationOrTemplate')}
                            </PPTypography>
                        </div>
                        <form id="formEventDocumentation" method="post" onSubmit={handleDocumentationSubmit}>
                            <PPBox borderRadius="lg" p={2} mb={2} coloredShadow="light">
                                <PPBox mb={2}>
                                    <PPTypography variant="h6" fontWeight="medium">
                                        {t("template")}
                                    </PPTypography>
                                    <Divider />
                                    <Grid container>
                                        <Grid item xs={10} md={10} lg={11} textAlign="left">
                                            <Autocomplete
                                                disablePortal
                                                options={pageState.templates}
                                                onChange={handleTemplateOnChange}
                                                renderInput={(params) => <TextField {...params} label="Vorlage" variant="standard" style={{ maxWidth: "97%" }} />}
                                            />
                                        </Grid>
                                        <Grid item xs={2} md={2} lg={1} textAlign="right">
                                            <PPButton style={{ minWidth: "40px", marginTop: "10px" }} variant="outlined" color="primary" onClick={handleOpenTemplateEditor} size="small">
                                                <Icon>add</Icon>
                                            </PPButton>
                                        </Grid>
                                    </Grid>
                                    <div>
                                        <PPTypography variant="caption" color="text">
                                            {t('selectTemplateorAddNewOne')}
                                        </PPTypography>
                                    </div>
                                </PPBox>
                            </PPBox>
                            <PPBox borderRadius="lg" p={2} mb={2} coloredShadow="light">
                                <PPBox mb={2}>
                                    <PPTypography variant="h6" fontWeight="medium">
                                        {t("documentation")}
                                    </PPTypography>
                                    <Divider />
                                </PPBox>
                                <PPBox mb={2}>
                                    <PPInput
                                        label={t("documentationDate")} InputLabelProps={{ shrink: true }} type="datetime-local"
                                        value={moment(pageState.documentationDate).format("yyyy-MM-DD HH:mm") || ''}
                                        onChange={(e) => {
                                            setPageState(x => ({ ...x, documentationDate: e.target.value ? e.target.value : moment(e.target.value).format("yyyy-MM-DD HH:mm") }));
                                        }} variant="standard" fullWidth required />
                                </PPBox>
                                <PPBox mb={2}>
                                    <ReactQuill theme="snow" value={pageState.notes} onChange={handleNotesChange} required />
                                </PPBox>
                                <PPBox mb={2}>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleFileInputChange}
                                    />
                                </PPBox>
                            </PPBox>
                        </form>
                        {
                            pageState.fileNames != null && pageState.fileNames.length > 0 ?
                                <DocumentationFilesList id={pageState.documentationId} patientId={pageState.patientId} fileNames={pageState.fileNames} friendlyFileNames={pageState.friendlyFileNames} viewMode="Edit" />
                                : null
                        }
                    </div>
            }
        </PPBox>
    );
}

export default DocumentationEditor;
