import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import PPInput from "components/PPInput";
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import moment from "moment";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import { useTranslation } from 'react-i18next';

const PracticeHolidayEditor = ({ open, holidayId, handleRefreshListing, handleCloseDialog }) => {
    const { t } = useTranslation();
    const [pageState, setPageState] = useState({
        id: holidayId,
        holiday: '',
        duration: 1,
        date: moment(new Date()).format("yyyy-MM-DD"),
    });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const durationArray = [
        { value: 1, label: "1 Tag" },
        { value: 0.5, label: "Halber Tag" },
    ];

    const handleHolidayChange = (e) => {
        setPageState(x => ({ ...x, holiday: e.target.value ? e.target.value : '' }));
    };

    const handleDurationOnChange = (e, newValue) => {
        setPageState(x => ({ ...x, duration: newValue ? newValue.value : '' }));
    };

    const handleCloseEditor = (e) => {
        handleCloseDialog();
        resetForm();
    };

    const handleConfirmDelete = () => {
        var body = JSON.stringify({
            "id": pageState.id,
        });
        var serviceUrl = appEndPoints.practiceHolidaysEndpoint.replace("{hostname}", appEndPoints.hostname);

        fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                handleRefreshListing();
                handleCloseEditor();
            })
            .catch(error => { console.log('error', error); })
    };

    const handleDeleteClick = (e) => {
        e.preventDefault();
        setConfirmDialogOpen(true);

    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        var body = JSON.stringify({
            "id": pageState.id ? pageState.id : null,
            "holiday": pageState.holiday ? pageState.holiday : '',
            "duration": pageState.duration ? pageState.duration : 1,
            "date": moment(pageState.holidayDate).format("yyyy-MM-DD"),
        });

        var fetchURL = appEndPoints.practiceHolidaysEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('POST', body, 'json');
        if (pageState.id !== null && pageState.id !== '') {
            fetchURL = appEndPoints.practiceHolidaysEndpoint.replace("{hostname}", appEndPoints.hostname);
            requestOptions = getRequestOptions('PUT', body, 'json');
        }
        fetch(fetchURL, requestOptions)
            .then(resp => resp.text())
            .then(resp => {
                handleRefreshListing();
                handleCloseEditor();
            })
            .catch(error => { console.log('error', error); })
    };

    function fetchPracticeHolidayDetails() {
        if (holidayId !== null && holidayId !== '' && open === true) {
            var fetchURL = appEndPoints.practiceHolidaysEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + holidayId;
            fetch(fetchURL, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    setPageState(x => ({
                        ...x, id: resp.id,
                        holiday: resp.holiday,
                        duration: resp.duration,
                        holidayDate: resp.date,
                    }));
                })
                .catch(error => { console.log('error', error); })
        }
        else {
            resetForm();
        }
    }

    function resetForm() {
        setPageState(x => ({
            ...x, id: '',
            holiday: '',
            duration: 1,
            holidayDate: moment(new Date()).format("yyyy-MM-DD"),
        }));
    }

    useEffect(() => {
        fetchPracticeHolidayDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, holidayId]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseEditor();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                {
                    holidayId !== null && holidayId !== '' ?
                        <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>event</Icon>&nbsp;{t("particulars")}</div>
                        : <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>event</Icon>&nbsp;Neuer Praxis Schließtag</div>
                }
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <form id="formManageHoliday" method="post" onSubmit={handleFormSubmit}>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="text" placeholder={t("holiday")} label={t("holiday")} value={pageState.holiday} onChange={handleHolidayChange} variant="standard" required fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Autocomplete
                                    disablePortal
                                    name="duration"
                                    value={durationArray.find((option) => option.value === pageState.duration)}
                                    options={durationArray}
                                    onChange={handleDurationOnChange}
                                    renderInput={(params) => <TextField {...params} name="duration" label={t("duration")} fullWidth variant="standard" required />}
                                />
                            </Grid>
                        </Grid>
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput
                                    label={t("date")} InputLabelProps={{ shrink: true }} type="date"
                                    value={moment(pageState.holidayDate).format("yyyy-MM-DD") || ''}
                                    onChange={(e) => {
                                        setPageState(x => ({ ...x, holidayDate: e.target.value ? e.target.value : moment(e.target.value).format("yyyy-MM-DD") }));
                                    }}
                                    variant="standard" fullWidth required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                            </Grid>
                        </Grid>
                    </PPBox>
                </form>
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseEditor} fullWidth>
                            <Icon>cancel</Icon>&nbsp;Schließen
                        </PPButton>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        {
                            pageState.id !== null && pageState.id !== '' ?
                                <PPButton variant="gradient" color="error" onClick={handleDeleteClick} fullWidth>
                                    <Icon>delete</Icon>&nbsp;{t("delete")}
                                </PPButton> : null
                        }
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="primary" type="submit" form="formManageHoliday" fullWidth>
                            <Icon>save</Icon>&nbsp;{t("save")}
                        </PPButton>
                    </Grid>
                </Grid>
            </DialogActions>
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                message={`${t("confirmDeletePracticeClosingDayMsg")}?`}
            />
        </Dialog>
    );
}

export default PracticeHolidayEditor;