import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import Breadcrumbs from "controls/Breadcrumbs";
// import LanguagePopover from "controls/Navbars/DashboardNavbar/LanguagePopover";
import NotificationItem from "controls/Items/NotificationItem";
import MenuItemWithoutLink from "controls/Items/NotificationItem/MenuItemWithoutLink";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "controls/Navbars/DashboardNavbar/styles";
import {
  usePraxiProUIController,
  setMiniSidenav,
  setProfilePhoto,
} from "context";
import PPAvatar from "components/PPAvatar";
import { appEndPoints } from "appSettings";
import { getRequestOptions, getCurrentUser } from "controls/Helpers";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = usePraxiProUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode, profilePhoto } = controller;
  const [openUserMenu, setOpenUserMenu] = useState(false);
  //const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const currentUser = JSON.parse(window.localStorage.getItem('currentUser'));

  useEffect(() => {
    // Setting the navbar type
    // if (fixedNavbar) {
    //   setNavbarType("sticky");
    // } else {
    setNavbarType("static");
    // }

    // A function that sets the transparent state of the navbar.
    // function handleTransparentNavbar() {
    //   setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    // }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    //window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    //handleTransparentNavbar();

    // Remove event listener on cleanup
    //return () => window.removeEventListener("scroll", handleTransparentNavbar);

    loadUserProfile(getCurrentUser().id, getCurrentUser().photoFileName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  //const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  //const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  //const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenUserMenu = (event) => setOpenUserMenu(event.currentTarget);
  const handleCloseUserMenu = () => setOpenUserMenu(false);

  // // Render the notifications menu
  // const renderMenu = () => (
  //   <Menu
  //     anchorEl={openMenu}
  //     anchorReference={null}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "left",
  //     }}
  //     open={Boolean(openMenu)}
  //     onClose={handleCloseMenu}
  //     sx={{ mt: 2 }}
  //   >
  //     <NotificationItem icon={<Icon>sticky_note_2</Icon>} title="Note Updated" content="A simple primary " />
  //     <NotificationItem icon={<Icon>sticky_note_2</Icon>} title="Note Updated" content="A simple primary " />
  //     <NotificationItem icon={<Icon>sticky_note_2</Icon>} title="Note Updated" content="A simple primary " />
  //   </Menu>
  // );

  // Render the user menu
  const renderUserMenu = () => (
    <Menu
      anchorEl={openUserMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openUserMenu)}
      onClose={handleCloseUserMenu}
      sx={{ mt: 2 }}
    >
      <Link to="/profile" onClick={handleCloseUserMenu}>
        <MenuItemWithoutLink icon={<Icon>person</Icon>} title="Mein Profil" />
      </Link>
      <Link to="/change-password" onClick={handleCloseUserMenu}>
        <MenuItemWithoutLink icon={<Icon>key</Icon>} title="Kennwort ändern" />
      </Link>
      <NotificationItem icon={<Icon>logout</Icon>} title="Abmelden" onClick={handleLogout} />
    </Menu>
  );

  const loadUserProfile = async (userId, photoFileName) => {
    var fetchURL = appEndPoints.getFileBinary.replace("{hostname}", appEndPoints.hostname).replace("{id}", userId).replace("{fileName}", photoFileName);
    fetch(fetchURL, getRequestOptions())
      .then(response => {
        if (!response.ok) {
          throw new Error('Error occured');
        }
        return response.blob();
      })
      .then(blob => {
        setProfilePhoto(dispatch, URL.createObjectURL(blob));
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  }

  // Perform user logout
  const handleLogout = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = "/sign-in"
  }
  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <PPBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs name={route[route.length - 1]} />
        </PPBox>
        {isMini ? null : (
          <PPBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <PPBox pr={1}>
              <PPInput label="Search here" />
            </PPBox> */}
            <PPBox className="headerToolbar" color={light ? "white" : "inherit"}>
              {/* <Link to="/account/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
              {/* <LanguagePopover />  */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
                style={{ position: 'relative' }} // Add relative positioning to the button
              >
                <Icon sx={iconsStyle}>notifications</Icon>
                <span class="notification-count">5</span>
              </IconButton>
              {renderMenu()} */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="user-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenUserMenu}>
                <PPAvatar alt={currentUser.firstName + " " + currentUser.lastName} bgColor="secondary" shadow="md" src={profilePhoto} />
              </IconButton>
              {renderUserMenu()}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

            </PPBox>
          </PPBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
