import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import PPInput from "components/PPInput";
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    getRequestOptions, getCurrentUser,
    getCurrentUserId, userHasRole,
    ROLES, weekdaysJson,
} from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import moment from "moment";
import PPSnackbar from "components/PPSnackbar";
import PPTypography from "components/PPTypography";
import Switch from "@mui/material/Switch";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import AlertDialog from 'controls/Dialogs/AlertDialog';
import Spinner from "controls/Spinner";
import { usePraxiProUIController, setProfilePhoto } from "context";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import { useTranslation } from 'react-i18next';

const UserEditor = ({ open, userId, handleRefreshListing, handleCloseDialog }) => {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        id: userId,
        firstName: '',
        lastName: '',
        birthdate: '',
        email: '',
        joiningDate: moment(new Date()).format("yyyy-MM-DD HH:mm"),
        entitledVacationDaysPerYear: 30,
        remainingVacationDaysBeforeJoiningDate: 0,
        //workingHoursPerWeek: 36,
        overallWorkingHoursBeforeJoiningDate: 0,
        hiddenDaysInCalendar: [0,6],
        workingDays: [1, 2, 3, 4, 5],
        state: 'BE',
        socialNumber: '',
        taxNumber: '',
        city: null,
        street: null,
        postalCode: null,
        roles: [],
        imageTempUrl: '',
        imageUrl: '',
        photo: null,
        newStatus: 0,
        currentUser: getCurrentUser(),
        statesArray: [
            { value: "BW", label: "Baden-Württemberg" },
            { value: "BY", label: "Bavaria" },
            { value: "BE", label: "Berlin" },
            { value: "BB", label: "Brandenburg" },
            { value: "HB", label: "Bremen" },
            { value: "HH", label: "Hamburg" },
            { value: "HE", label: "Hesse" },
            { value: "NI", label: "Lower Saxony" },
            { value: "MV", label: "Mecklenburg-Vorpommern" },
            { value: "NW", label: "North Rhine-Westphalia" },
            { value: "RP", label: "Rhineland-Palatinate" },
            { value: "SL", label: "Saarland" },
            { value: "ST", label: "Saxony-Anhalt" },
            { value: "SH", label: "Schleswig-Holstein" },
            { value: "TH", label: "Thuringia" },
        ],
    });
    const [dispatch] = usePraxiProUIController();
    const [administratorSwitch, setAdministratorSwitch] = useState(false);
    const [calendarViewerSwitch, setCalendarViewerSwitch] = useState(false);
    const [leaveRequestManagerSwitch, setLeaveRequestManagerSwitch] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorSB, setErrorSB] = useState(false);
    const closeErrorSB = () => setErrorSB(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);

    const handleFirstNameChange = (e) => {
        setPageState(x => ({ ...x, firstName: e.target.value ? e.target.value : '' }));
    };
    const handleLastNameChange = (e) => {
        setPageState(x => ({ ...x, lastName: e.target.value ? e.target.value : '' }));
    };
    const handleEmailChange = (e) => {
        setPageState(x => ({ ...x, email: e.target.value ? e.target.value : '' }));
    };
    const handleSocialNumberChange = (e) => {
        setPageState(x => ({ ...x, socialNumber: e.target.value ? e.target.value : '' }));
    };
    const handleTaxNumberChange = (e) => {
        setPageState(x => ({ ...x, taxNumber: e.target.value ? e.target.value : '' }));
    };
    const handleEntitledVacationDaysPerYearChange = (e) => {
        setPageState(x => ({ ...x, entitledVacationDaysPerYear: e.target.value ? e.target.value : '' }));
    };
    const handleRemaingVacationDaysBeforeJoiningDateChange = (e) => {
        setPageState(x => ({ ...x, remainingVacationDaysBeforeJoiningDate: e.target.value ? e.target.value : '' }));
    };
    // const handleWorkingHoursPerWeekChange = (e) => {
    //     setPageState(x => ({ ...x, workingHoursPerWeek: e.target.value ? e.target.value : '' }));
    // };
    const handleOverallWorkingHoursChange = (e) => {
        setPageState(x => ({ ...x, overallWorkingHoursBeforeJoiningDate: e.target.value ? e.target.value : '' }));
    };
    const handleCityChange = (e) => {
        setPageState(x => ({ ...x, city: e.target.value ? e.target.value : '' }));
    };
    const handleStreetChange = (e) => {
        setPageState(x => ({ ...x, street: e.target.value ? e.target.value : '' }));
    };
    const handlePostalCodeChange = (e) => {
        setPageState(x => ({ ...x, postalCode: e.target.value ? e.target.value : '' }));
    };
    const handleStateOnChange = (e, newValue) => {
        setPageState(x => ({ ...x, state: newValue ? newValue.value : 'BE' }));
    };
    const handleAdministratorSwitch = () => {
        setAdministratorSwitch(!administratorSwitch);
        if (!administratorSwitch) {
            const updatedRoles = pageState.roles.concat({ id: ROLES.ADMINISTRATOR });
            setPageState(x => ({ ...x, roles: updatedRoles }));
        }
        else {
            const updatedRoles = pageState.roles.filter(item => item.id !== ROLES.ADMINISTRATOR);
            setPageState(x => ({ ...x, roles: updatedRoles }));
        }
    }
    const handleCalendarViewerSwitch = () => {
        setCalendarViewerSwitch(!calendarViewerSwitch);
        if (!calendarViewerSwitch) {
            const updatedRoles = pageState.roles.concat({ id: ROLES.CALENDAR_VIEWER });
            setPageState(x => ({ ...x, roles: updatedRoles }));
        }
        else {
            const updatedRoles = pageState.roles.filter(item => item.id !== ROLES.CALENDAR_VIEWER);
            setPageState(x => ({ ...x, roles: updatedRoles }));
        }
    }
    const handleLeaveRequestManagerSwitch = () => {
        setLeaveRequestManagerSwitch(!leaveRequestManagerSwitch);
        if (!leaveRequestManagerSwitch) {
            const updatedRoles = pageState.roles.concat({ id: ROLES.LEAVE_REQUEST_MANAGER });
            setPageState(x => ({ ...x, roles: updatedRoles }));
        }
        else {
            const updatedRoles = pageState.roles.filter(item => item.id !== ROLES.LEAVE_REQUEST_MANAGER);
            setPageState(x => ({ ...x, roles: updatedRoles }));
        }
    }

    const handleWorkingDaysSwitchChange = (dayId) => {
        setPageState((prevPageState) => {
            const isDayHidden = prevPageState.workingDays.includes(dayId);

            return {
                ...prevPageState,
                workingDays: isDayHidden
                    ? prevPageState.workingDays.filter((day) => day !== dayId)
                    : [...prevPageState.workingDays, dayId],
            };
        });
    };

    const renderWeekdays = Object.keys(weekdaysJson).map((k, index) => (
        <Grid item xs={6} md={4} lg={4} key={index}>
            <PPBox display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
                lineHeight={1}>
                <PPTypography variant="button" fontWeight="regular" textTransform="capitalize">
                    {weekdaysJson[k].longName}
                </PPTypography>
                <Switch checked={pageState.workingDays.includes(weekdaysJson[k].index)}
                    onChange={() => handleWorkingDaysSwitchChange(weekdaysJson[k].index)}
                />
            </PPBox>
        </Grid>
    ));

    const handleCloseEditor = (e) => {
        handleCloseDialog();
        setAdministratorSwitch(false);
        setCalendarViewerSwitch(false);
        setLeaveRequestManagerSwitch(false);
        resetForm();
    };

    const handleFileChange = (event) => {
        // Validate file extension
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = event.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (file.size > 2 * 1024 * 1024) {
                setErrorMessage(t("invalidPhotoSizeMsg"));
                setErrorSB(true);
            }
            else if (!allowedExtensions.includes(fileExtension)) {
                setErrorMessage(t("selectValidImageMsg"));
                setErrorSB(true);
            }
            else {
                setErrorSB(false);
                setPageState(x => ({ ...x, photo: file }));
            }
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setOpenSpinnerPanel(true);
        var submitData = new FormData();
        submitData.append("firstName", pageState.firstName);
        submitData.append("lastName", pageState.lastName);
        submitData.append("birthdate", pageState.birthdate ? pageState.birthdate : '');
        submitData.append("email", pageState.email);
        submitData.append("joiningDate", pageState.joiningDate ? pageState.joiningDate : '');
        submitData.append("entitledVacationDaysPerYear", pageState.entitledVacationDaysPerYear ? pageState.entitledVacationDaysPerYear : 0);
        submitData.append("remainingVacationDaysBeforeJoiningDate", pageState.remainingVacationDaysBeforeJoiningDate ? pageState.remainingVacationDaysBeforeJoiningDate : 0);
        submitData.append("overallWorkingHoursBeforeJoiningDate", pageState.overallWorkingHoursBeforeJoiningDate ? pageState.overallWorkingHoursBeforeJoiningDate : 0);
        //submitData.append("workingHoursPerWeek", pageState.workingHoursPerWeek ? pageState.workingHoursPerWeek : 0);
        submitData.append("hiddenDaysInCalendar", pageState.hiddenDaysInCalendar ? JSON.stringify(pageState.hiddenDaysInCalendar) : '[0,6]');
        submitData.append("workingDays", pageState.workingDays ? JSON.stringify(pageState.workingDays) : '[1,2,3,4,5]');
        submitData.append("state", pageState.state ? pageState.state : 'BE');
        submitData.append("socialNumber", pageState.socialNumber ? pageState.socialNumber : '');
        submitData.append("taxNumber", pageState.taxNumber ? pageState.taxNumber : '');
        submitData.append("city", pageState.city ? pageState.city : '');
        submitData.append("street", pageState.street ? pageState.street : '');
        submitData.append("postalCode", pageState.postalCode ? pageState.postalCode : '');
        submitData.append("photoFile", pageState.photo ? pageState.photo : null);
        submitData.append("roles", pageState.roles ? pageState.roles.map(item => item.id).join(",") : "");

        var fetchURL = appEndPoints.postUserEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('POST', submitData);
        if (pageState.id !== null && pageState.id !== '') {
            fetchURL = appEndPoints.putUserEndpoint.replace("{hostname}", appEndPoints.hostname);
            submitData.append("id", pageState.id ? pageState.id : null);
            requestOptions = getRequestOptions('PUT', submitData);
        }
        fetch(fetchURL, requestOptions)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else if (resp.status === 409) {
                    setAlertMessage('Es existiert bereits ein Benutzer mit derselben E-Mail-Adresse. Bitte wählen Sie eine andere E-Mail.');
                    setAlertDialogOpen(true);
                }
                return resp.json();
            })
            .then(resp => {
                if (pageState.id !== null && pageState.id !== '' && pageState.id === getCurrentUserId()) {
                    let updatedUser = pageState.currentUser;
                    // Check if updatedUser.address is null, and if so, initialize it
                    if (!updatedUser.address) {
                        updatedUser.address = {};
                    }
                    updatedUser.firstName = submitData.get('firstName');
                    updatedUser.lastName = submitData.get('lastName');
                    updatedUser.birthdate = submitData.get('birthdate') ? moment(submitData.get('birthdate'), "yyyy-MM-DD").format("DD.MM.YYYY") : null;
                    updatedUser.address.city = submitData.get('city');
                    updatedUser.address.street = submitData.get('street');
                    updatedUser.address.postalCode = submitData.get('postalCode');
                    updatedUser.socialNumber = submitData.get('socialNumber');
                    updatedUser.taxNumber = submitData.get('taxNumber');
                    updatedUser.photoFileName = resp.photoFileName;
                    window.localStorage.setItem('currentUser', JSON.stringify(updatedUser));
                    setPageState(old => ({ ...old, viewMode: "view", isLoading: false }));
                    fetchURL = appEndPoints.getFileBinary.replace("{hostname}", appEndPoints.hostname).replace("{id}", pageState.id).replace("{fileName}", updatedUser.photoFileName);
                    fetch(fetchURL, getRequestOptions())
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Error occured');
                            }
                            return response.blob();
                        })
                        .then(blob => {
                            setProfilePhoto(dispatch, URL.createObjectURL(blob));
                        })
                        .catch(error => {
                            console.error('Fetch error:', error);
                        });
                }
                handleRefreshListing();
                setOpenSpinnerPanel(false);
                handleCloseEditor();
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    const handleUserDeactivateClick = (e) => {
        e.preventDefault();
        setConfirmMessage(`${t("confirmDeactivateUserMsg")}?`);
        setConfirmDialogOpen(true);
        setPageState(x => ({ ...x, newStatus: 2 }));
    };

    const handleUserActivateClick = (e) => {
        e.preventDefault();
        setConfirmMessage(`${t("confirmActivateUserMsg")}?`);
        setConfirmDialogOpen(true);
        setPageState(x => ({ ...x, newStatus: 1 }));
    };

    const handleConfirmAction = () => {
        setOpenSpinnerPanel(true);
        var body = JSON.stringify({
            "id": pageState.id,
            "status": pageState.newStatus
        });
        var serviceUrl = appEndPoints.patchUserStatusEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions('PATCH', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                handleRefreshListing();
                handleCloseEditor();
                setOpenSpinnerPanel(false);
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    function fetchUserDetails() {
        if (userId !== null && userId !== '' && open === true) {
            setOpenSpinnerPanel(true);
            var fetchURL = appEndPoints.getUsersEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + userId;
            fetch(fetchURL, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    setPageState(x => ({
                        ...x, id: resp.id,
                        firstName: resp.firstName,
                        lastName: resp.lastName,
                        birthdate: resp.birthdate ? resp.birthdate : null,
                        email: resp.email,
                        joiningDate: resp.joiningDate ? resp.joiningDate : null,
                        entitledVacationDaysPerYear: resp.entitledVacationDaysPerYear,
                        remainingVacationDaysBeforeJoiningDate: resp.remainingVacationDaysBeforeJoiningDate,
                        overallWorkingHoursBeforeJoiningDate: resp.overallWorkingHoursBeforeJoiningDate,
                        //workingHoursPerWeek: resp.workingHoursPerWeek,
                        hiddenDaysInCalendar: resp.hiddenDaysInCalendar ? JSON.parse(resp.hiddenDaysInCalendar) : [0,6],
                        workingDays: resp.workingDays ? JSON.parse(resp.workingDays) : [1, 2, 3, 4, 5],
                        state: resp.state,
                        socialNumber: resp.socialNumber,
                        taxNumber: resp.taxNumber,
                        status: resp.status,
                        city: resp.address && resp.address.city ? resp.address.city : null,
                        street: resp.address && resp.address.street ? resp.address.street : null,
                        postalCode: resp.address && resp.address.postalCode ? resp.address.postalCode : null,
                        roles: resp.roles,
                    }));
                    setAdministratorSwitch(userHasRole(ROLES.ADMINISTRATOR, resp.roles));
                    setLeaveRequestManagerSwitch(userHasRole(ROLES.LEAVE_REQUEST_MANAGER, resp.roles));
                    setCalendarViewerSwitch(userHasRole(ROLES.CALENDAR_VIEWER, resp.roles));

                    if (resp.photoFileName) {
                        var fetchURL = appEndPoints.getFileBinary.replace("{hostname}", appEndPoints.hostname).replace("{id}", userId).replace("{fileName}", resp.photoFileName);
                        fetch(fetchURL, getRequestOptions())
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error('Error occured');
                                }
                                return response.blob();
                            })
                            .then(blob => {
                                setPageState(x => ({ ...x, imageUrl: URL.createObjectURL(blob) }));
                            })
                            .catch(error => {
                                console.error('Fetch error:', error);
                            });
                    }
                    setOpenSpinnerPanel(false);
                })
                .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
        }
        else {
            resetForm();
        }
    }

    function resetForm() {
        setPageState(x => ({
            ...x, id: '', firstName: '', lastName: '', birthdate: '', email: '',
            entitledVacationDaysPerYear: 30, remaingVacationDaysBeforeJoiningDate: 0,
            overallWorkingHoursBeforeJoiningDate: 0, hiddenDaysInCalendar: [0, 6],
            workingDays: [1, 2, 3, 4, 5], joiningDate: moment(new Date()).format("yyyy-MM-DD HH:mm"), state: 'BE',
            city: '', street: '', postalCode: '', socialNumber: '', taxNumber: '', roles: [], status: '',
            imageTempUrl: '', imageUrl: '', photo: null
        }));
    }

    useEffect(() => {
        if (pageState.photo) {
            setPageState(x => ({ ...x, imageTempUrl: URL.createObjectURL(pageState.photo) }));
        }
    }, [pageState.photo]);

    useEffect(() => {
        fetchUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, userId]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseEditor();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                {
                    userId !== null && userId !== '' ?
                        <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>people</Icon>&nbsp;{t("particulars")}</div>
                        : <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>people</Icon>&nbsp;{t("newUser")}</div>
                }
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <form id="formManageUser" method="post" onSubmit={handleFormSubmit}>
                    <PPBox mb={2}>
                        <input
                            accept=".jpg,.png,.jpeg"
                            type="file"
                            id="select-image"
                            name="photo"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        <div className="profile-photo-container">
                            {(pageState.imageUrl !== null && pageState.imageUrl !== '') || (pageState.imageTempUrl !== null && pageState.imageTempUrl !== '') ? (
                                <img src={pageState.imageTempUrl ? pageState.imageTempUrl : pageState.imageUrl} alt="profile" className="profile-photo" />
                            ) : <div className="upload-photo-placeholder">{t("uploadProfilePhoto")}</div>}
                        </div>
                        <label htmlFor="select-image">
                            <PPButton variant="gradient" color="primary" component="span">
                                <Icon>save</Icon>&nbsp;{t("uploadPhoto")}
                            </PPButton>
                        </label>
                        <PPSnackbar
                            color="error"
                            icon="warning"
                            title="Error"
                            content={errorMessage}
                            dateTime=""
                            open={errorSB}
                            onClose={closeErrorSB}
                            close={closeErrorSB}
                            bgWhite
                        />
                    </PPBox>
                    <PPBox borderRadius="lg" p={2} mt={3} mb={3} coloredShadow="light">
                        <PPBox mb={2}>
                            <PPTypography variant="h6" fontWeight="medium">
                                {t("personalData")}
                            </PPTypography>
                            <Divider />
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField type="text" label={t("firstName")} value={pageState.firstName} onChange={handleFirstNameChange} variant="standard" required fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField type="text" label={t("surName")} value={pageState.lastName} onChange={handleLastNameChange} variant="standard" required fullWidth />
                                </Grid>
                            </Grid>
                        </PPBox>
                        <PPBox mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <PPInput type="email" placeholder="Email" label="Email" value={pageState.email} onChange={handleEmailChange} variant="standard" required fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <PPInput
                                        label={t("dateOfBirth")} InputLabelProps={{ shrink: true }} type="date"
                                        value={moment(pageState.birthdate).format("yyyy-MM-DD") || ''}
                                        onChange={(e) => {
                                            setPageState(x => ({ ...x, birthdate: e.target.value ? e.target.value : moment(e.target.value).format("yyyy-MM-DD") }));
                                        }}
                                        variant="standard" fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </PPBox>
                        <PPBox mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField type="text" label={t("socialSecurityNumber")} placeholder={t("socialSecurityNumber")} value={pageState.socialNumber} onChange={handleSocialNumberChange} variant="standard" fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField type="text" label={t("tin")} placeholder={t("tin")} value={pageState.taxNumber} onChange={handleTaxNumberChange} variant="standard" fullWidth />
                                </Grid>
                            </Grid>
                        </PPBox>
                    </PPBox>
                    <PPBox borderRadius="lg" p={2} mb={3} coloredShadow="light">
                        <PPBox mb={2}>
                            <PPTypography variant="h6" fontWeight="medium">
                                {t("address")}
                            </PPTypography>
                            <Divider />
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Autocomplete
                                        disablePortal
                                        name="state"
                                        value={pageState.statesArray.find((option) => option.value === pageState.state)}
                                        options={pageState.statesArray}
                                        onChange={handleStateOnChange}
                                        renderInput={(params) => <TextField {...params} name="state" label={t("state")} fullWidth variant="standard" required />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField type="text" label={t("city")} value={pageState.city} onChange={handleCityChange} variant="standard" fullWidth />
                                </Grid>
                            </Grid>
                        </PPBox>
                        <PPBox mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField type="text" label={t("street")} value={pageState.street} onChange={handleStreetChange} variant="standard" fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField type="text" label={t("postalCode")} value={pageState.postalCode} onChange={handlePostalCodeChange} variant="standard" fullWidth />
                                </Grid>
                            </Grid>
                        </PPBox>
                    </PPBox>
                    <PPBox borderRadius="lg" p={2} mb={3} coloredShadow="light">
                        <PPBox mb={2}>
                            <PPTypography variant="h6" fontWeight="medium">
                                {t("profile")}
                            </PPTypography>
                            <Divider />
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <PPInput type="number" placeholder={t("vacationDaysPerYear")} label={t("vacationDaysPerYear")} value={pageState.entitledVacationDaysPerYear} onChange={handleEntitledVacationDaysPerYearChange} variant="standard" required fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <PPInput type="number" placeholder={t("remainingVacationDaysJoinDate")} label={t("remainingVacationDaysJoinDate")} value={pageState.remainingVacationDaysBeforeJoiningDate} onChange={handleRemaingVacationDaysBeforeJoiningDateChange} variant="standard" required fullWidth />
                                </Grid>
                            </Grid>
                        </PPBox>
                        <PPBox mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <PPInput
                                        label={t("joinDate")} InputLabelProps={{ shrink: true }} type="date"
                                        value={moment(pageState.joiningDate).format("yyyy-MM-DD") || ''}
                                        onChange={(e) => {
                                            setPageState(x => ({ ...x, joiningDate: e.target.value ? e.target.value : moment(e.target.value).format("yyyy-MM-DD") }));
                                        }}
                                        variant="standard" required fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    {/* <PPInput type="number" placeholder={$t("workingHoursPerWeek")} label={$t("workingHoursPerWeek")} value={pageState.workingHoursPerWeek} onChange={handleWorkingHoursPerWeekChange} variant="standard" required fullWidth /> */}
                                </Grid>
                            </Grid>
                        </PPBox>
                        <PPBox mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField type="text" label={t("overallWorkingHours")} placeholder={t("overallWorkingHours")} value={pageState.overallWorkingHoursBeforeJoiningDate} onChange={handleOverallWorkingHoursChange} variant="standard" fullWidth />
                                    <div>
                                        <PPTypography variant="caption" color="text">
                                            {t('overallWorkingHoursMsg')}
                                        </PPTypography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>

                                </Grid>
                            </Grid>
                        </PPBox>
                    </PPBox>
                    <PPBox borderRadius="lg" p={2} mb={3} coloredShadow="light">
                        <PPBox mb={2}>
                            <PPTypography variant="h6" fontWeight="medium">
                                {t("workDays")}
                            </PPTypography>
                            <Divider />
                            <Grid container spacing={3}>
                                {renderWeekdays}
                            </Grid>
                        </PPBox>
                    </PPBox>
                    <PPBox borderRadius="lg" p={2} mb={3} coloredShadow="light">
                        <PPBox mb={2}>
                            <PPTypography variant="h6" fontWeight="medium">
                                {t("permissions")}
                            </PPTypography>
                            <Divider />
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <PPBox
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mt={3}
                                        lineHeight={1}>
                                        <span>{t("administrator")}</span>
                                        <Switch checked={administratorSwitch}
                                            disabled={pageState.id !== null && pageState.id !== '' && pageState.id === getCurrentUserId()}
                                            onChange={handleAdministratorSwitch} />
                                    </PPBox>
                                    <PPBox
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mt={3}
                                        lineHeight={1}>
                                        <span>{t("absenceRequest")}</span>
                                        <Switch checked={leaveRequestManagerSwitch}
                                            disabled={pageState.id !== null && pageState.id !== '' && pageState.id === getCurrentUserId()}
                                            onChange={handleLeaveRequestManagerSwitch} />
                                    </PPBox>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <PPBox
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mt={3}
                                        lineHeight={1}>
                                        <span>{t("viewAllCalendar")}</span>
                                        <Switch checked={calendarViewerSwitch}
                                            disabled={pageState.id !== null && pageState.id !== '' && pageState.id === getCurrentUserId()}
                                            onChange={handleCalendarViewerSwitch} />
                                    </PPBox>
                                </Grid>
                            </Grid>
                        </PPBox>
                    </PPBox>
                </form>
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseEditor} fullWidth>
                            <Icon>cancel</Icon>&nbsp;{t("cancel")}
                        </PPButton>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        {
                            pageState.id !== null && pageState.id !== '' && pageState.id !== getCurrentUserId() && pageState.status === 1 ?
                                <PPButton variant="gradient" color="error" onClick={handleUserDeactivateClick} fullWidth>
                                    <Icon>delete</Icon>&nbsp;{t("delete")}
                                </PPButton> : null
                        }
                        {
                            pageState.id !== null && pageState.id !== '' && pageState.id !== getCurrentUserId() && pageState.status !== 1 ?
                                <PPButton variant="gradient" color="success" onClick={handleUserActivateClick} fullWidth>
                                    <Icon>check</Icon>&nbsp;{t("activate")}
                                </PPButton> : null
                        }
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="primary" type="submit" form="formManageUser" fullWidth>
                            <Icon>save</Icon>&nbsp;{t("save")}
                        </PPButton>
                    </Grid>
                </Grid>
            </DialogActions>
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmAction}
                message={confirmMessage}
            />
            <AlertDialog
                open={alertDialogOpen}
                onClose={() => setAlertDialogOpen(false)}
                message={alertMessage}
            />
            <Spinner open={openSpinnerPanel} />
        </Dialog>
    );
}

export default UserEditor;