import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import Icon from "@mui/material/Icon";
import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import breakpoints from "assets/theme/base/breakpoints";
import SettingsListing from "./components/SettingsListing";
import EventCategoryListing from './components/EventCategoryListing';
import { setPageTitle } from "controls/Helpers";
import { useTranslation } from 'react-i18next';

function Settings() {
    const { t } = useTranslation();
    setPageTitle(t('settings'));
    const [tabIndex, setTabIndex] = useState(0);
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");

    const handleTabsChange = (e, newIdex) => {
        setTabIndex(newIdex);
    };

    useEffect(() => {
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }
        window.addEventListener("resize", handleTabsOrientation);
        handleTabsOrientation();
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    return (
        <PrivateLayout>
            <PPBox pb={2}>
                <Card>
                    <PPBox p={2}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={6} lg={6} sx={{ ml: "auto" }}>
                                <AppBar position="static">
                                    <Tabs orientation={tabsOrientation} value={tabIndex} onChange={handleTabsChange}>
                                        <Tab label={t("categories")} icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>{'event'}</Icon>} />
                                        <Tab label={t("basic")} icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>{'settings'}</Icon>} />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </PPBox>
                </Card>
            </PPBox>
            <Card>
                {
                    tabIndex === 0 ? <EventCategoryListing /> : null
                }
                {
                    tabIndex === 1 ? <SettingsListing /> : null
                }
            </Card>
        </PrivateLayout>
    );
}

export default Settings;
