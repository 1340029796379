import React, { useState, useEffect } from 'react';
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import { appEndPoints } from "appSettings";
import moment from 'moment';
import { getCurrentUser, getRequestOptions, userHasAccess, ROLES } from "controls/Helpers";
import DataTable from "controls/Tables/DataTable";
import PracticeHolidayEditor from './PracticeHolidayEditor';
import { useTranslation } from 'react-i18next';

function PracticeHolidays() {
    const { t } = useTranslation();
    const [pageState, setPageState] = useState({
        isAdminUser: userHasAccess(ROLES.ADMINISTRATOR, getCurrentUser().roles),
        selectedHolidayId: '',
        data: [],
        rows: []
    });
    const [dialogEditorOpen, setDialogEditorOpen] = useState(false);

    const columns = [
        { Header: `${t("holiday")}`, accessor: "holiday", align: "Left" },
        { Header: `${t("date")}`, accessor: "date", align: "Left" },
        { Header: `${t('duration')}`, accessor: "duration", align: "Left" },
        ...(pageState.isAdminUser ? [{ Header: "Aktion", accessor: "action", align: "center" }] : []),
    ];

    let rows =
        pageState.data.map((e, index) => ({
            holiday: (
                <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {e.holiday}
                </PPTypography>
            ),
            date: (
                <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {e.date ? moment(e.date, "YYYY-MM-DD").format("DD.MM.YYYY") : "-"}
                </PPTypography>
            ),
            duration: (
                <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {e.duration} {t("tag")}
                </PPTypography>
            ),
            action: (
                pageState.isAdminUser ? (
                    <PPTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <PPButton variant="outlined" color="primary" size="small" onClick={() => handleEditClick(e.id)}>
                            <Icon>edit</Icon>&nbsp;{t("edit")}
                        </PPButton>
                    </PPTypography>
                ) : null
            ),
        }));

    const handleEditClick = (arg) => {
        setPageState(old => ({ ...old, selectedHolidayId: arg }));
        setDialogEditorOpen(true);
    };

    const handleOpenEditorDialog = (arg) => {
        setPageState(old => ({ ...old, selectedHolidayId: '' }));
        setDialogEditorOpen(true);
    };

    const handleCloseEditorDialog = () => {
        setDialogEditorOpen(false);
    };

    const handleRefreshListing = () => {
        fetchGridData();
    };

    const fetchGridData = async () => {
        try {
            const fetchURL = appEndPoints.practiceHolidaysEndpoint.replace("{hostname}", appEndPoints.hostname);
            const response = await fetch(fetchURL, getRequestOptions());
            const data = await response.json();
            setPageState((old) => ({ ...old, data: data }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchGridData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PPBox pt={0} pb={3}>
            {
                pageState.isAdminUser ?
                    <PPBox p={2}>
                        <Grid container>
                            <Grid item xs={12} md={6} lg={6} textAlign="left">
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} textAlign="right">
                                <PPButton color="primary" onClick={handleOpenEditorDialog}>
                                    <Icon>add_circle</Icon>&nbsp;
                                    {t("add")}
                                </PPButton>
                            </Grid>
                        </Grid>
                    </PPBox> : null
            }
            <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
            />
            <PracticeHolidayEditor holidayId={pageState.selectedHolidayId} open={dialogEditorOpen} handleCloseDialog={handleCloseEditorDialog} handleRefreshListing={handleRefreshListing} />
        </PPBox>
    );
}

export default PracticeHolidays;
