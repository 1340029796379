import React, { useState } from 'react'
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { truncateHTML } from "controls/Helpers";
import { useTranslation } from 'react-i18next';

function TemplateCard({ template, handleTemplateEditClick, handleTemplateDeleteClick }) {
    const { t } = useTranslation();
    const [menu, setMenu] = useState(false);
    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(false);

    const handleEditClick = () => {
        handleTemplateEditClick(template.id);
        closeMenu();
    }

    const handleDeleteClick = () => {
        handleTemplateDeleteClick(template.id);
        closeMenu();
    }

    return (
        <PPBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            borderRadius="lg" p={2} m={2} coloredShadow="light">
            <PPBox width="100%" display="flex" flexDirection="column">
                <Grid container mb={2}>
                    <Grid item xs={10} md={10} lg={10}>
                        <PPTypography variant="button" fontWeight="medium" textTransform="capitalize">
                            {template.name}
                        </PPTypography>
                        <PPTypography variant="button" color="text" fontWeight="light">
                            <div dangerouslySetInnerHTML={{ __html: truncateHTML(template.content, 180) }} />
                        </PPTypography>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2} textAlign={"right"}>
                        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
                            more_vert
                        </Icon>
                        <Menu anchorEl={menu}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(menu)} onClose={closeMenu}>
                            <MenuItem color="error" onClick={handleEditClick}>
                                <Icon>edit</Icon>&nbsp;{t("edit")}
                            </MenuItem>
                            <MenuItem color="secondary" onClick={handleDeleteClick}>
                                <Icon>delete</Icon>&nbsp;{t("delete")}
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </PPBox>
        </PPBox>
    );
}

export default TemplateCard;