import { useState } from "react";
import Card from "@mui/material/Card";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPInput from "components/PPInput";
import PPButton from "components/PPButton";
import BasicLayout from "pages/account/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpg";
import logoImage from "assets/images/logos/praxipro-logo.png";
import { appEndPoints } from "appSettings";
import { Icon, Divider, Link, Select, MenuItem } from "@mui/material";
import { onSuccessfulLogin, setPageTitle } from "controls/Helpers";
import Spinner from "controls/Spinner";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

function SignIn() {
  const { t } = useTranslation();
  setPageTitle(t('signin'));
  const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [invalidPractice, setInvalidPractice] = useState(false);
  const [selectedPracticeId, setSelectedPracticeId] = useState('00000000-0000-0000-0000-000000000000');
  const [practices, setPractices] = useState([]);

  const handleSignInFormSubmit = (e) => {
    e.preventDefault();
    setInvalidCredentials(false);
    setInvalidPractice(false);
    setOpenSpinnerPanel(true);
    const signInForm = document.getElementById("formSignIn");
    const formData = new FormData(signInForm);
    var object = {};
    formData.forEach((value, key) => object[key] = value);
    var requestOptions = {
      method: 'POST',
      body: JSON.stringify(object),
      headers: { 'Content-Type': 'application/json' }
    };
    var serviceUrl = appEndPoints.postAuthenticateEndpoint.replace("{hostname}", appEndPoints.hostname);
    var status = 200;
    fetch(serviceUrl, requestOptions)
      .then(resp => {
        status = resp.status;
        return resp.json();
      })
      .then(resp => {
        setOpenSpinnerPanel(false);
        if (status === 409) {
          setPractices(resp.practiceData);
          setInvalidPractice(true);
        }
        else if (status === 401) {
          setInvalidCredentials(true);
        }
        else if (status === 200) {
          onSuccessfulLogin(resp.token);
        }
      })
      .catch(error => { setInvalidCredentials(true); setOpenSpinnerPanel(false); console.log('error', error); })
  };

  return (
    <BasicLayout image={bgImage}>
      <Helmet>
        <link rel="canonical" href="https://app.praxipro.de/sign-in" />
      </Helmet>
      <Card>
        <PPBox
          mx={2}
          pt={2} pl={2} pr={2}
          textAlign="center">
          <a href="https://www.praxipro.de" target="_blank" rel="noreferrer">
            <PPBox component="img" src={logoImage} alt="" width="171px" height="68px" mt={1} />
          </a>
          <Divider />
          <PPTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
            {t("signin")}
          </PPTypography>
          <PPTypography display="block" variant="button" color="dark" my={1}>
            {t("signinIntro")}
          </PPTypography>
        </PPBox>
        <PPBox pb={3} px={3}>
          {
            invalidCredentials ?
              <PPTypography variant="button" color="error" fontWeight="medium" textGradient>
                {t('invalidCredentials')}
              </PPTypography> : null
          }
          {
            invalidPractice ?
              <PPTypography variant="button" color="error" fontWeight="medium" textGradient>
                {t('selectPractice')}
              </PPTypography> : null
          }
          <PPBox pt={2} component="form" role="form" method="POST" id="formSignIn" name="formSignIn" onSubmit={handleSignInFormSubmit}>
            <PPBox mb={2}>
              <PPInput type="email" label={t('email')} name="email" autoComplete="email" fullWidth required />
            </PPBox>
            <PPBox>
              <PPInput type="password" label={t('password')} name="password" autoComplete="password" fullWidth required />
            </PPBox>
            {
              practices.length > 1 ? <PPBox mt={2}>
                <Select className='selectList' fullWidth value={selectedPracticeId}
                  onChange={(e, newInputValue) => {
                    setSelectedPracticeId(newInputValue.props.value);
                  }}>
                  <MenuItem key='00000000-0000-0000-0000-000000000000' value='00000000-0000-0000-0000-000000000000'>{t("choosePractice")}</MenuItem>
                  {
                    practices.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  }
                </Select>
                <input type="hidden" id="hfSelectedPracticeId" name="practiceId" value={selectedPracticeId} />
              </PPBox> : null
            }
            <PPBox textAlign="left">
              <Link href="/forgot-password" >
                <PPTypography
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient>
                  {t("forgotPassword")}
                </PPTypography>
              </Link>
            </PPBox>
            <PPBox mt={3} mb={1}>
              <PPButton variant="gradient" color="primary" type="submit" form="formSignIn" fullWidth>
                <Icon>login</Icon>&nbsp;{t("signin")}
              </PPButton>
            </PPBox>
            <Spinner open={openSpinnerPanel} text={`${t("loads")}...`} />
            <PPBox mt={3} mb={1} textAlign="center">
              <PPTypography variant="button" color="text">
                {t("dontHaveAccount")}&nbsp;
                <Link href="/sign-up" >
                  <PPTypography
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient>
                    {t("signup")}
                  </PPTypography>
                </Link>
              </PPTypography>
            </PPBox>
          </PPBox>
        </PPBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
