import React, { memo, useRef } from 'react';
import Card from "@mui/material/Card";
import PPBox from "components/PPBox";
import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import { useState, useEffect } from 'react'
import { getRequestOptions, setPageTitle } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import PatientEditor from "./components/PatientEditor";
import DocumentationDialog from "./components/Documentation/DocumentationDialog";
import PatientsListing from "./components/PatientsListing";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment } from '@mui/material';
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import AlertDialog from 'controls/Dialogs/AlertDialog';
import { useTranslation } from 'react-i18next';

const Patients = memo((props) => {
    const { t } = useTranslation();
    setPageTitle(t('patients'));
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        totalRecords: 0,
        currentPage: 1,
        pageSize: 25,
        hideLoadMore: false,
        selectedPatientId: null,
        searchQuery: '',
    });
    const patientsGridRef = useRef();
    const [dialogEditorOpen, setDialogEditorOpen] = useState(false);
    const [dialogDocumentationOpen, setDialogDocumentationOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const handleOpenEditorDialog = (arg) => {
        setPageState(old => ({ ...old, selectedPatientId: '' }));
        setDialogEditorOpen(true);
    };

    const handleCloseEditorDialog = () => {
        setDialogEditorOpen(false);
    };

    const handleCloseDocumentationDialog = () => {
        setDialogDocumentationOpen(false);
    };

    const handlePatientDocumentationClick = (arg) => {
        setPageState(old => ({ ...old, selectedPatientId: arg }));
        setDialogDocumentationOpen(true);
    };

    const handlePatientEditClick = (arg) => { // bind with an arrow function
        setPageState(old => ({ ...old, selectedPatientId: arg }));
        setDialogEditorOpen(true);
    };

    const handlePatientDeleteClick = (arg) => {
        setPageState(old => ({ ...old, selectedPatientId: arg }));
        setConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        var body = JSON.stringify({
            "id": pageState.selectedPatientId,
        });
        var serviceUrl = appEndPoints.deletePatientEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                setDialogEditorOpen(false);
                handleRefreshListing();
            })
            .catch(error => { 
                console.log('error', error);
                setAlertDialogOpen(true);
            })

        // Close the dialog after the action is performed
        setConfirmDialogOpen(false);
    };

    const handleSearchQueryChange = (e) => {
        e.preventDefault();
        setPageState(x => ({ ...x, searchQuery: e.target.value }));
        patientsGridRef.current.handleSearch(e.target.value);
    }

    const handleSearchClick = (e) => {
        e.preventDefault();
        patientsGridRef.current.handleSearch(pageState.searchQuery);
    }

    const handleRefreshListing = () => {
        patientsGridRef.current.refreshListing();
    };

    useEffect(() => {
    }, []);

    return (
        <PrivateLayout>
            <Card>
                <PPBox p={2}>
                    <Grid container>
                        <Grid item xs={6} md={6} lg={6} textAlign="left">
                            <TextField
                                label={`${t("searchPatient")}`} fullWidth
                                variant="outlined" autoComplete="false"
                                value={pageState.searchQuery} onChange={handleSearchQueryChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearchClick}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} textAlign="right">
                            <PPButton color="primary" onClick={handleOpenEditorDialog}>
                                <Icon>add_circle</Icon>&nbsp;
                                {t("add")}
                            </PPButton>
                        </Grid>
                    </Grid>
                </PPBox>
                <PPBox pt={1} px={2}>
                    <PatientEditor patientId={pageState.selectedPatientId} open={dialogEditorOpen}
                        handlePatientDeleteClick={handlePatientDeleteClick}
                        handleRefreshListing={handleRefreshListing}
                        handleCloseDialog={handleCloseEditorDialog} />
                    <DocumentationDialog patientIds={[pageState.selectedPatientId]} open={dialogDocumentationOpen} handleCloseDialog={handleCloseDocumentationDialog}></DocumentationDialog>
                    <PPBox pb={2} style={{ minHeight: "800px" }}>
                        <PatientsListing fetchURL={appEndPoints.searchPatientsEndpoint.replace("{hostname}", appEndPoints.hostname)} ref={patientsGridRef}
                            handleSearch={pageState.handleSearch}
                            //TODO: check this pageState usage, it doesn't exists.
                            refreshListing={pageState.refreshListing}
                            searchQuery={pageState.searchQuery}
                            handlePatientDocumentationClick={handlePatientDocumentationClick}
                            handlePatientEditClick={handlePatientEditClick}
                            handlePatientDeleteClick={handlePatientDeleteClick}
                        />
                        <ConfirmDialog
                            open={confirmDialogOpen}
                            onClose={() => setConfirmDialogOpen(false)}
                            onConfirm={handleConfirmDelete}
                            message={t("confirmDeletePatientMsg")}
                        />
                        <AlertDialog
                            open={alertDialogOpen}
                            onClose={() => setAlertDialogOpen(false)}
                            message={t("unableToDeleteMsg")}
                        />
                    </PPBox>
                </PPBox>
            </Card>
        </PrivateLayout>
    );
});

export default Patients;
