export const getRepeatIntervalArray = ({t}) => {
    var elements = [];
    elements.push({
        value: 1,
        label: `${t("everyWeek")}}`,
    });
    for (var i = 2; i <= 31; i++) {
        elements.push({
            value: i,
            label: `${i} ${t("weeks")}`,//`${i}` + " Wochen",
        });
    }
    return elements;
};

export const getStatusArray = ({t}) => {
    var elements = [];
    elements.push({
        value: -1,
        label: `${t("scheduled")}`,
    });
    elements.push({
        value: 1,
        label: "Completed",
    });
    elements.push({
        value: 2,
        label: "Canceled before 24 hours",
    });
    elements.push({
        value: 3,
        label: "Canceled on the same day",
    });
    return elements;
};