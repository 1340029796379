import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import { useTranslation } from 'react-i18next';

function NoRecords() {
    const { t } = useTranslation();
    return (
        <PPBox style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
            <PPTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                {t("noRecords")}
            </PPTypography>
        </PPBox>
    );
}

export default NoRecords;