import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import PPInput from "components/PPInput";
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@material-ui/core';
import PPTypography from "components/PPTypography";
import FormControlLabel from '@mui/material/FormControlLabel';
import { getRequestOptions, getCurrentUserId, weekdaysJson } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Spinner from "controls/Spinner";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from "@mui/material/Grid";
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import moment from 'moment';
import { trim } from "stylis";
import Menu from "@mui/material/Menu";
import { getRepeatIntervalArray } from "./Common";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import AlertDialog from 'controls/Dialogs/AlertDialog';
import { useTranslation } from 'react-i18next';

const ManageEvent = ({ open, formData, handleRefreshCalendar, handleCloseDialog }) => {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [categories, setCategories] = useState([]);
    const [patients, setPatients] = useState([]);
    const [patientSearchInputValue, setPatientSearchInputValue] = useState('');
    const [repeatIntervalArray, setRepeatIntervalArray] = useState([]);
    const [forceDatePickerOpen, setForceDatePickerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorDeleteEl, setAnchorDeleteEl] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [alertDialogMsg, setAlertDialogMsg] = useState('');
    let todayDay = weekdaysJson.find(day => day.index === new Date().getDay());
    const [pageState, setPageState] = useState({
        eventId: '',
        eventCategoryId: '',
        startDateTime: moment(new Date()).format("yyyy-MM-DD HH:mm"),
        endDateTime: moment(new Date()).add(1, 'M').format("yyyy-MM-DD"),
        duration: 60,
        homeVisit: false,
        recurrent: false,
        repeatInterval: 1,
        description: '',
        groupId: '',
        selectedEventStartDate: moment(new Date()).format("yyyy-MM-DD"),
        selectedWeekdaysByIndex: [todayDay.index],
        selectedWeekdaysByName: [todayDay.longName],
        enableDocumentation: 0,
        eventInstanceId: '',
        notes: '',
        status: -1,
        fileNames: [],
        filesToUpload: [],
        tabIndex: 0,
        selectedSubmitMode: -1,
        selectedPatients: [],
    });

    const handleDurationChange = (e) => {
        setPageState(x => ({ ...x, duration: e.target.value ? e.target.value : '' }));
    };

    const handleHomeVisit = (e) => {
        setPageState(x => ({ ...x, homeVisit: e.target.checked }));
        if (e.target.checked)
            setPageState(x => ({ ...x, duration: parseInt(pageState.duration) + 15 }));
        else
            setPageState(x => ({ ...x, duration: parseInt(pageState.duration) - 15 }));
    };

    const handleRecurrent = (e) => {
        setPageState(x => ({ ...x, recurrent: e.target.checked }));
        if (e.target.checked) {
            var startDay = weekdaysJson.find(day => day.index === moment(pageState.startDateTime).day());
            setPageState(x => ({
                ...x, selectedWeekdaysByIndex: [startDay.index],
                selectedWeekdaysByName: [startDay.longName],
                endDateTime: moment(pageState.startDateTime).add(1, 'M').format("yyyy-MM-DD")
            }));
        }
        else {
            setPageState(x => ({ ...x, endDateTime: moment(pageState.startDateTime).format("yyyy-MM-DD") }));
        }
    };

    const handleCategoryOnChange = (e, newValue) => {
        setPageState(x => ({ ...x, eventCategoryId: newValue ? newValue.value : '' }));
        if (newValue && newValue.duration > 0)
            setPageState(x => ({ ...x, duration: newValue.duration }));
        setPageState(x => ({ ...x, enableDocumentation: newValue.enableDocumentation }));
    };

    const handlePatientOnChange = (e, newValue) => {
        setPageState(x => ({ ...x, selectedPatients: newValue }));
    };

    const handleStartDateTimeOnChange = (e) => {
        setPageState(x => ({ ...x, startDateTime: e.target.value ? e.target.value : moment(new Date()).format("yyyy-MM-DD HH:mm") }));
    };

    const handleRepeatIntervalOnChange = (e, newValue) => {
        setPageState(x => ({ ...x, repeatInterval: newValue ? newValue.value : '' }));
    };

    const handleDescriptionOnChange = (e) => {
        setPageState(x => ({ ...x, description: e.target.value ? e.target.value : '' }));
    };

    const handleWeekdaysSelection = (e, newWeekdaysSelection) => {
        if (newWeekdaysSelection.length) {
            var daysIndex = [];
            var longNames = [];
            Object.keys(weekdaysJson).forEach((k) => {
                if (newWeekdaysSelection.indexOf(weekdaysJson[k].index) !== -1) {
                    daysIndex.push(weekdaysJson[k].index);
                    longNames.push(weekdaysJson[k].longName);
                }
            });
            setPageState(x => ({ ...x, selectedWeekdaysByIndex: daysIndex, selectedWeekdaysByName: longNames }));
        }
    };

    const handleButtonClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleDeleteButtonClick = (e) => {
        setAnchorDeleteEl(e.currentTarget);
    };

    const handleMenuItemClick = (e) => {
        setAnchorEl(null);
        handleEventFormSubmit(e);
    };

    function resetForm() {
        var startDateTime = moment(new Date()).format("yyyy-MM-DD HH:mm");
        var endDateTime = moment(new Date()).add(1, 'M').format("yyyy-MM-DD");
        todayDay = weekdaysJson.find(day => day.index === new Date().getDay());
        if (formData !== null && formData.start !== null && formData.start !== undefined &&
            (formData.id === undefined || formData.id === null || formData.id === '')) {
            startDateTime = moment(formData.start).format("yyyy-MM-DD HH:mm");
            endDateTime = moment(startDateTime).add(1, 'M').format("yyyy-MM-DD");
            todayDay = weekdaysJson.find(day => day.index === moment(formData.start).day());
        }
        setAnchorEl(null);
        setAnchorDeleteEl(null);
        setPageState(x => ({
            ...x, eventId: '',
            eventCategoryId: '',
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            duration: 60,
            homeVisit: false,
            recurrent: false,
            repeatInterval: 1,
            description: '',
            groupId: '',
            selectedEventStartDate: moment(startDateTime).format("yyyy-MM-DD"),
            selectedWeekdaysByIndex: [todayDay.index],
            selectedWeekdaysByName: [todayDay.longName],
            enableDocumentation: 1,
            eventInstanceId: '',
            notes: '',
            status: -1,
            fileNames: [],
            filesToUpload: [],
            tabIndex: 0,
            selectedSubmitMode: -1,
            selectedPatients: []
        }));
    }

    const handleEventFormSubmit = (e) => {
        e.preventDefault();
        if (pageState.recurrent && moment(pageState.startDateTime).format("yyyy-MM-DD") > moment(pageState.endDateTime).format("yyyy-MM-DD")) {
            setAlertDialogMsg(t("invalidStartEndDates"));
            setAlertDialogOpen(true);
            return;
        }
        setOpenSpinnerPanel(true);
        var serviceUrl = '';
        var submitMode = e.target.getAttribute('data-submitmode') ? e.target.getAttribute('data-submitmode') : "0";
        var body = JSON.stringify({
            "id": pageState.eventId ? pageState.eventId : null,
            //"patientId": pageState.patientId ? pageState.patientId : null,
            "patientIds": pageState.selectedPatients.map(item => item.value),
            "eventCategoryId": pageState.eventCategoryId ? pageState.eventCategoryId : null,
            "groupId": pageState.groupId ? pageState.groupId : null,
            "startDateTime": pageState.startDateTime, //"2023-01-11T13:00:00",
            "endDateTime": moment(pageState.endDateTime).format("yyyy-MM-DD"), //"9999-01-01T13:45:00",
            "selectedEventStartDate": pageState.selectedEventStartDate,
            "duration": pageState.duration,
            "homeVisit": pageState.homeVisit,
            "recurrent": pageState.recurrent,
            "repeatInterval": pageState.repeatInterval,
            "daysOfWeek": pageState.selectedWeekdaysByIndex.join(','),
            "description": pageState.description,
            "submitMode": submitMode,
            "ownerId": getCurrentUserId(),
        });
        const startTime = moment(pageState.startDateTime).format('HH:mm:ss'); // Extract time as string
        const isTimeBetween6And19 = moment(startTime, 'HH:mm:ss').isBetween(
            moment('06:00:00', 'HH:mm:ss'),
            moment('19:00:00', 'HH:mm:ss'),
            'hours'
        );
        if (isTimeBetween6And19) {
            if (pageState.eventId) {
                serviceUrl = appEndPoints.putEventEndpoint.replace("{hostname}", appEndPoints.hostname);
                fetch(serviceUrl, getRequestOptions('PUT', body, 'json'))
                    .then(resp => resp.json())
                    .then(resp => {
                        handleCloseDialog();
                        handleRefreshCalendar();
                        setOpenSpinnerPanel(false);
                        resetForm();
                    })
                    .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
            }
            else {
                serviceUrl = appEndPoints.postEventEndpoint.replace("{hostname}", appEndPoints.hostname);
                fetch(serviceUrl, getRequestOptions('POST', body, 'json'))
                    .then(resp => resp.json())
                    .then(resp => {
                        handleCloseDialog();
                        handleRefreshCalendar();
                        setOpenSpinnerPanel(false);
                        resetForm();
                    })
                    .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
            }
        }
        else {
            setOpenSpinnerPanel(false);
            setAlertDialogMsg(t("invalidEventTime"));
            setAlertDialogOpen(true);
        }
    };

    const handleEventDeleteClick = (e) => {
        e.preventDefault();
        setPageState(old => ({ ...old, selectedSubmitMode: e.target.getAttribute('data-submitmode') ? e.target.getAttribute('data-submitmode') : "0" }));
        setConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        setOpenSpinnerPanel(true);
        // Close the dialog after the action is performed
        setConfirmDialogOpen(false);
        var body = JSON.stringify({
            "id": pageState.eventId,
            "selectedEventStartDate": pageState.selectedEventStartDate,
            "patientId": pageState.patientId ? pageState.patientId : null,
            "submitMode": pageState.selectedSubmitMode,
        });
        var serviceUrl = appEndPoints.deleteEventEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                resetForm();
                handleRefreshCalendar();
                setOpenSpinnerPanel(false);
                handleCloseDialog();
            })
            .catch(error => {
                setOpenSpinnerPanel(false);
                setAlertDialogMsg(t("unableToDeleteMsg"));
                setAlertDialogOpen(true);
                console.log('error', error);
            })
    };

    const handleCloseDialogClick = (e) => {
        e.preventDefault();
        handleCloseDialog();
        resetForm();
    }

    const handleOpenDatePickerClick = (e) => {
        setForceDatePickerOpen((forceDatePickerOpen) => !forceDatePickerOpen);
        if(!forceDatePickerOpen){
            const inputDateElement = document.getElementById('endDateInput');
            inputDateElement.showPicker();
        }
    }

    const renderWeekdays = Object.keys(weekdaysJson).map((k) => (
        <ToggleButton key={weekdaysJson[k].index} value={weekdaysJson[k].index} aria-label={weekdaysJson[k].index} className="wkdButton">
            {weekdaysJson[k].shortName}
        </ToggleButton>
    ));

    function fetchEventCategories() {
        var serviceUrl = appEndPoints.getEventCategoriesEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                var elements = [];
                elements.push({
                    value: "",
                    label: "",
                    duration: 0,
                    enableDocumentation: 1,
                });
                for (var i = 0; i < resp.length; i++) {
                    elements.push({
                        value: resp[i].id,
                        label: resp[i].name,
                        duration: resp[i].duration,
                        enableDocumentation: resp[i].enableDocumentation
                    });
                }
                setCategories(elements);
            })
    }

    function fetchPatients(val) {
        if (trim(val) !== '' && val !== '' && patientSearchInputValue !== '') {
            var serviceUrl = appEndPoints.searchPatientsEndpoint.replace("{hostname}", appEndPoints.hostname).replace("{pageNumber}", 1).replace("{pageSize}", 100) + "?q=" + trim(val);
            fetch(serviceUrl, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    var elements = pageState.patients ? pageState.patients : [];
                    elements.push({
                        value: "",
                        label: "",
                    });
                    for (var i = 0; i < resp.patients.length; i++) {
                        elements.push({
                            value: resp.patients[i].id,
                            label: resp.patients[i].firstName + ' ' + resp.patients[i].lastName + (resp.patients[i].email ? ' (' + resp.patients[i].email + ')' : ''),
                        });
                    }
                    setPatients(elements);
                })
                .catch(error => { console.log('error', error); })
        }
    }

    function fetchEventDetails() {
        if (formData !== null && formData.id !== null && formData.id !== undefined && formData.id !== '') {
            var serviceUrl = appEndPoints.getEventDetailsEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + formData.id;
            fetch(serviceUrl, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    var elements = [];
                    elements.push({
                        value: "",
                        label: "",
                    });
                    for (var i = 0; i < resp.eventPatients.length; i++) {
                        elements.push({
                            value: resp.eventPatients[i].patient.id,
                            label: resp.eventPatients[i].patient.firstName + ' ' + resp.eventPatients[i].patient.lastName + (resp.eventPatients[i].patient.email ? ' (' + resp.eventPatients[i].patient.email + ')' : ''),
                        });
                    }
                    setPatients(elements);
                    var selectedWeekdays = resp.daysOfWeek.split(',').map((x) => parseInt(x, 10));
                    var longNames = [];
                    Object.keys(weekdaysJson).forEach((k) => {
                        if (selectedWeekdays.indexOf(weekdaysJson[k].index) !== -1) {
                            longNames.push(weekdaysJson[k].longName);
                        }
                    });
                    setPageState(x => ({
                        ...x, eventId: formData.id,
                        eventCategoryId: resp.eventCategoryId ? resp.eventCategoryId : '',
                        startDateTime: moment(formData.start).format("yyyy-MM-DD") + ' ' + moment(resp.start).format("HH:mm"), endDateTime: resp.end, duration: resp.duration,
                        homeVisit: resp.homeVisit, recurrent: resp.recurrent,
                        repeatInterval: resp.repeatInterval ? resp.repeatInterval : 1,
                        description: resp.description ? resp.description : '',
                        selectedEventStartDate: moment(formData.start).format("yyyy-MM-DD") + ' ' + moment(resp.start).format("HH:mm"),
                        selectedWeekdaysByIndex: selectedWeekdays, selectedWeekdaysByName: longNames,
                        enableDocumentation: resp.eventCategory ? resp.eventCategory.enableDocumentation : 0,
                        eventInstanceId: '', notes: '', status: -1, fileNames: [], selectedPatients: elements.filter(x => x.value.trim() !== "")
                    }));
                    setPatientSearchInputValue("");
                })
                .catch(error => { console.log('error', error); })
        }
        else {
            setPatientSearchInputValue("");
            resetForm();
        }
    }

    useEffect(() => {
        fetchEventCategories();
        setRepeatIntervalArray(getRepeatIntervalArray({t}));
        var elements = [];
        elements.push({
            value: "",
            label: "",
        });
        setPatients(elements);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchEventDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseDialogClick();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                {formData !== null && formData.id !== null && formData.id !== '' ?
                    <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>edit_calendar</Icon>&nbsp;{t("appointmentDetails")}</div>
                    : <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>edit_calendar</Icon>&nbsp;{t("newAppointment")}</div>
                }
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <form id="formManageEvent" method="post" onSubmit={handleEventFormSubmit}>
                    <PPBox mb={2}>
                        <PPInput type="text" value={pageState.description} onChange={handleDescriptionOnChange} label={t("eventTitle")} placeholder={t("eventTitle")} name="description" variant="standard" fullWidth />
                    </PPBox>
                    <PPBox mb={2}>
                        <Autocomplete
                            disablePortal
                            name="category"
                            value={categories.find((option) => option.value === pageState.eventCategoryId)}
                            options={categories}
                            onChange={handleCategoryOnChange}
                            renderInput={(params) => <TextField {...params} name="category" label={t("category")} fullWidth variant="standard" />}
                        />
                        <div>
                            <PPTypography variant="caption" color="text">
                                {t('selectCategoryorAddNewOne')}
                            </PPTypography>
                        </div>
                    </PPBox>
                    <PPBox mb={2}>
                        <p>
                            Patient(en)
                        </p>
                        <Autocomplete
                            disablePortal
                            name="patient"
                            noOptionsText={`${t("quick")}: ${t("enterPatientName")}.`}
                            filterOptions={(x) => x}
                            options={patients}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            multiple
                            value={pageState.selectedPatients}
                            onChange={handlePatientOnChange}
                            //onKeyDown={handleKeyDown}
                            onInputChange={(e, newInputValue) => {
                                setPatientSearchInputValue(newInputValue);
                                fetchPatients(newInputValue);
                            }}
                            renderInput={(params) => <TextField {...params} id="txtPatient" name="patient" value={pageState.patientTextValue} label={t('writePatientName')} fullWidth variant="standard" />}
                        />
                        <div>
                            <PPTypography variant="caption" color="text">
                                {t('groupAppointmentMsg')}
                            </PPTypography>
                        </div>
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="datetime-local" label={`${t("date")} / ${t("time")}`} value={pageState.startDateTime} onChange={handleStartDateTimeOnChange} variant="standard" required fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="number" placeholder={t("durationInMins")} label={t("duration")} value={pageState.duration} InputProps={{ inputProps: { min: 15, max: 480 } }} onChange={handleDurationChange} name="duration" variant="standard" required fullWidth />
                            </Grid>
                        </Grid>
                    </PPBox>
                    <PPBox mb={2}>
                        <FormControlLabel control={<Switch color="primary" checked={pageState.homeVisit} onChange={handleHomeVisit} />} label={t("houseCall")} />
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <FormControlLabel control={<Switch checked={pageState.recurrent} onChange={handleRecurrent} />} label={t("repetition")} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                {pageState.recurrent ? <Autocomplete disablePortal name="repeatInterval"
                                    value={repeatIntervalArray.find((option) => option.value === pageState.repeatInterval)}
                                    options={repeatIntervalArray} onChange={handleRepeatIntervalOnChange}
                                    renderInput={(params) => <TextField {...params} name="repeatInterval" fullWidth variant="standard" />}
                                /> : null}
                            </Grid>
                        </Grid>
                    </PPBox>
                    {pageState.recurrent ?
                        <PPBox>
                            <ToggleButtonGroup
                                value={pageState.selectedWeekdaysByIndex} color="primary"
                                onChange={handleWeekdaysSelection}
                                aria-label="Mo" spacing={1}>
                                {renderWeekdays}
                            </ToggleButtonGroup>
                            <div>
                                <PPTypography variant="caption" color="text">
                                    {t("handleWeekdaysSelection")}.
                                </PPTypography>
                            </div>
                            <div className="recEndDate">
                                <PPTypography variant="button" color="dark" fontSize="small">
                                    ${t("every")} {pageState.selectedWeekdaysByName.join(', ')} ${t("until")} <Button className="btnPicker" variant="text" fontSize="small" onClick={handleOpenDatePickerClick}>{pageState.endDateTime !== null ? moment(pageState.endDateTime).format("DD.MM.yyyy") : `${t("setEndDate")}`} <Icon>calendar_month</Icon></Button>
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker className="hiddendpicker"
                                            open={forceDatePickerOpen}
                                            onClose={() => setForceDatePickerOpen(false)}
                                            inputFormat="dd.MM.yyyy"
                                            value={pageState.endDateTime}
                                            onChange={(newValue) => {
                                                //setDateValue(newValue);
                                                setPageState(x => ({ ...x, endDateTime: newValue }));
                                            }}
                                            renderInput={(params) => <TextField className="hiddendpicker" {...params} name="recurrenceEndDate" value={pageState.endDateTime} />}
                                        />
                                    </LocalizationProvider> */}
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        type="date"
                                        id="endDateInput"
                                        className="hiddendpicker"
                                        open={forceDatePickerOpen}
                                        onClose={() => setForceDatePickerOpen(false)}
                                        inputProps={{
                                            inputFormat: "dd.MM.yyyy",
                                            value: pageState.endDateTime ? moment(pageState.endDateTime).format("yyyy-MM-DD") : ''
                                        }}
                                        onChange={(e) => {
                                            setPageState(prevState => ({
                                                ...prevState,
                                                endDateTime: e.target.value ? moment(e.target.value, "yyyy-MM-DD").toDate() : null
                                            }));
                                        }}
                                    />
                                    {pageState.endDateTime !== null ? <Button variant="text" onClick={() => setPageState(x => ({ ...x, endDateTime: null }))}>${t("removeEndDate")}</Button> : null}
                                </PPTypography>
                            </div>
                        </PPBox> : null}
                </form>
            </DialogContent>
            <DialogActions>
                <Divider />
                {
                    pageState.tabIndex === 0 ?
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} lg={4}>
                                <PPButton variant="gradient" color="light" onClick={handleCloseDialogClick} fullWidth>
                                    <Icon>cancel</Icon>&nbsp;{t("close")}
                                </PPButton>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                {
                                    pageState.recurrent && pageState.eventId !== null && pageState.eventId !== '' ?
                                        <Box>
                                            <PPButton variant="gradient" color="error" fullWidth onClick={handleDeleteButtonClick}>
                                                <Icon>delete</Icon>&nbsp;{t("delete")}
                                            </PPButton>
                                            <Menu anchorEl={anchorDeleteEl} open={Boolean(anchorDeleteEl)} onClose={() => setAnchorDeleteEl(null)}>
                                                <MenuItem onClick={handleEventDeleteClick} data-submitmode="4">{t("thisEvent")}</MenuItem>
                                                <MenuItem onClick={handleEventDeleteClick} data-submitmode="5">{t("thisAndAllFollowingEvents")}</MenuItem>
                                                {/* <MenuItem onClick={handleEventDeleteClick} data-submitmode="6">{t("allEvents")}</MenuItem> */}
                                            </Menu>
                                        </Box>
                                        :
                                        !pageState.recurrent && pageState.eventId !== null && pageState.eventId !== '' ?
                                            <PPButton variant="gradient" color="error" onClick={handleEventDeleteClick} fullWidth data-submitmode="0">
                                                <Icon>delete</Icon>&nbsp;{t("delete")}
                                            </PPButton> : null
                                }
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                {
                                    pageState.recurrent && pageState.eventId !== null && pageState.eventId !== '' ?
                                        <Box>
                                            <PPButton variant="gradient" color="primary" fullWidth onClick={handleButtonClick}>
                                                <Icon>save</Icon>&nbsp;${t("save")}
                                            </PPButton>
                                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                                                <MenuItem onClick={handleMenuItemClick} data-submitmode="1">{t("thisEvent")}</MenuItem>
                                                <MenuItem onClick={handleMenuItemClick} data-submitmode="2">{t("thisAndAllFollowingEvents")}</MenuItem>
                                                <MenuItem onClick={handleMenuItemClick} data-submitmode="3">{t("allEvents")}</MenuItem>
                                            </Menu>
                                        </Box>
                                        :
                                        <PPButton variant="gradient" color="primary" type="submit" form="formManageEvent" fullWidth data-submitmode="0">
                                            <Icon>save</Icon>&nbsp;{t("save")}
                                        </PPButton>
                                }
                            </Grid>
                        </Grid> : null
                }
                {
                    pageState.tabIndex === 1 ?
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} lg={4}>
                                <PPButton variant="gradient" color="light" onClick={handleCloseDialogClick} fullWidth>
                                    <Icon>cancel</Icon>&nbsp;{t("close")}
                                </PPButton>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                {
                                    <PPButton variant="gradient" color="primary" type="submit" form="formEventDocumentation" fullWidth>
                                        <Icon>save</Icon>&nbsp;{t("save")}
                                    </PPButton>
                                }
                            </Grid>
                        </Grid> : null
                }
            </DialogActions>
            <Spinner open={openSpinnerPanel} />
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                message={t("confirmDeleteEventMsg")}
            />
            <AlertDialog
                open={alertDialogOpen}
                onClose={() => setAlertDialogOpen(false)}
                message={alertDialogMsg}
            />
        </Dialog>
    );
}

export default ManageEvent;