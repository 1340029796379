import React, { useState, useEffect } from 'react'
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import moment from "moment";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PPAvatar from "components/PPAvatar";
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import { useTranslation } from 'react-i18next';

function PatientCard({ id, name, birthdate, email, primaryPhone, secondaryPhone, photoFileName, handlePatientDocumentationClick, handlePatientEditClick, handlePatientDeleteClick }) {
    const { t } = useTranslation();
    const [menu, setMenu] = useState(false);
    const [photo, setPhoto] = useState(null);
    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(false);

    const calculateAge = (birthdate) => {
        if (!birthdate) return '';
        const formattedDate = moment(birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        const birthdateDate = moment(formattedDate);
        const today = moment();
        let age = today.diff(birthdateDate, 'years');
        return ' - (' + age + ' Jahre)';
    };

    const handleEditClick = () => {
        handlePatientEditClick(id);
        closeMenu();
    }

    const handleDeleteClick = () => {
        handlePatientDeleteClick(id);
        closeMenu();
    }

    const handleDocumentationClick = () => {
        handlePatientDocumentationClick(id);
    }

    useEffect(() => {
        if (photoFileName) {
            var fetchURL = appEndPoints.getFileBinary.replace("{hostname}", appEndPoints.hostname).replace("{id}", id).replace("{fileName}", photoFileName);
            fetch(fetchURL, getRequestOptions())
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error occured');
                    }
                    return response.blob();
                })
                .then(blob => {
                    setPhoto(URL.createObjectURL(blob));
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <PPBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            borderRadius="lg" p={2} coloredShadow="light">
            <PPBox width="100%" display="flex" flexDirection="column">
                <Grid container mb={2}>
                    <Grid item xs={2} md={2} lg={2}>
                        <PPAvatar src={photo} shadow="md" bgColor="light" />
                    </Grid>
                    <Grid item xs={8} md={8} lg={8}>
                        <PPTypography variant="button" fontWeight="medium" textTransform="capitalize">
                            {name}
                        </PPTypography>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2} textAlign={"right"}>
                        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
                            more_vert
                        </Icon>
                        <Menu anchorEl={menu}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(menu)} onClose={closeMenu}>
                            <MenuItem color="error" onClick={handleEditClick}>
                                <Icon>edit</Icon>&nbsp;{t("edit")}
                            </MenuItem>
                            <MenuItem color="secondary" onClick={handleDeleteClick}>
                                <Icon>delete</Icon>&nbsp;{t("delete")}
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                <PPTypography mb={1.5} variant="caption" fontWeight="medium">
                    <Icon>cake</Icon> {birthdate ? birthdate : 'NA'}{calculateAge(birthdate)}
                </PPTypography>
                <PPTypography mb={1.5} variant="caption" fontWeight="medium">
                    <Icon>email</Icon> {email ? email : 'NA'}
                </PPTypography>
                <PPTypography mb={1.5} variant="caption" fontWeight="medium">
                    <Icon>phone</Icon> {primaryPhone ? primaryPhone : 'NA'}
                </PPTypography>
                <PPTypography mb={1.5} variant="caption" fontWeight="medium">
                    <Icon>phone</Icon> {secondaryPhone ? secondaryPhone : 'NA'}
                </PPTypography>
                <div style={{ textAlign: "right" }}>
                    <PPButton variant="outlined" color="primary" size="small" onClick={handleDocumentationClick}>
                        <Icon>attach_file</Icon>&nbsp;{t("documentation")}
                    </PPButton>
                </div>
            </PPBox>
        </PPBox>
    );
}

export default PatientCard;