import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import PPInput from "components/PPInput";
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

const SettingEditor = ({ open, settingId, handleRefreshListing, handleCloseDialog }) => {
    const { t } = useTranslation();
    const [pageState, setPageState] = useState({
        id: settingId,
        displayName: '',
        description: '',
        value: '',
        type: '',
    });

    const handleValueChange = (e) => {
        setPageState(x => ({ ...x, value: e.target.value ? e.target.value : '' }));
    };

    const handleCloseEditor = (e) => {
        handleCloseDialog();
        setPageState(x => ({
            ...x, id: '', displayName: '', description: '',
            value: '',
        }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        var submitData = new FormData();
        submitData.append("id", pageState.id);
        submitData.append("value", pageState.value);
        var fetchURL = appEndPoints.putSettingEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(fetchURL, getRequestOptions('PUT', submitData))
            .then(resp => resp.text())
            .then(resp => {
                if (resp === "true") {
                    handleRefreshListing();
                    handleCloseEditor();
                }
            })
            .catch(error => { console.log('error', error); })
    };

    function fetchSettingDetails() {
        setPageState(x => ({ ...x, id: '', imageUrl: '' })); // this is needed to avoid errors when switching between users
        if (settingId !== null && settingId !== '' && open === true) {
            var fetchURL = appEndPoints.getSettingsEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + settingId;
            fetch(fetchURL, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    setPageState(x => ({
                        ...x, id: resp.id,
                        displayName: resp.displayName,
                        value: resp.value,
                        description: resp.description,
                        type: resp.type,
                    }));
                })
                .catch(error => { console.log('error', error); })
        }
        else {
            setPageState(x => ({ ...x, id: '', displayName: '', description: '', value: '', }));
        }
    }

    useEffect(() => {
        fetchSettingDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, settingId]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseEditor();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>settings</Icon>&nbsp;{t("particulars")}</div>
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <form id="formManageSetting" method="post" onSubmit={handleFormSubmit}>
                    <PPBox mb={2}>
                        Name: {pageState.displayName}
                    </PPBox>
                    <PPBox mb={2}>
                        {t("description")}: {pageState.description}
                    </PPBox>
                    <PPBox mb={2}>
                        <PPInput type="text" label={t("value")} value={pageState.value} onChange={handleValueChange} variant="standard" fullWidth required />
                    </PPBox>
                </form>
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseEditor} fullWidth>
                            <Icon>cancel</Icon>&nbsp;{t("close")}
                        </PPButton>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>

                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="primary" type="submit" form="formManageSetting" fullWidth>
                            <Icon>save</Icon>&nbsp;{t("save")}
                        </PPButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default SettingEditor;