//import React, { useState } from 'react'
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import moment from "moment";
import Icon from "@mui/material/Icon";
//import Menu from "@mui/material/Menu";
//import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from 'react-i18next';

function NoteCard({ note, handleNoteEditClick, handleNoteDeleteClick }) {
    const { t } = useTranslation();
    //const [menu, setMenu] = useState(false);
    //const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    //const closeMenu = () => setMenu(false);

    const handleEditClick = () => {
        handleNoteEditClick(note.id);
        //closeMenu();
    }

    // const handleDeleteClick = () => {
    //     handleNoteDeleteClick(note.id);
    //     closeMenu();
    // }

    return (
        <Grid item xs={12} md={6} lg={4}>
            <PPBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                borderRadius="lg" p={2} coloredShadow="light">
                <PPBox width="100%" display="flex" flexDirection="column">
                    {/* <PPBox>
                        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
                            more_vert
                        </Icon>
                        <Menu anchorEl={menu}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(menu)} onClose={closeMenu}>
                            <MenuItem color="error" onClick={handleEditClick}>
                                <Icon>edit</Icon>&nbsp;Bearbeiten
                            </MenuItem>
                            <MenuItem color="secondary" onClick={handleDeleteClick}>
                                <Icon>delete</Icon>&nbsp;Löschen
                            </MenuItem>
                        </Menu>
                    </PPBox> */}
                    <PPBox mb={2} lineHeight={1}>
                        <PPTypography component="a" href="#" onClick={handleEditClick} variant="button" color="text" fontWeight="light" className="noteCardContent">
                            <div dangerouslySetInnerHTML={{ __html: note.content }} />
                        </PPTypography>
                    </PPBox>
                    <PPBox display="flex" justifyContent="space-between" alignItems="center">
                        {note.shared ?
                            <PPBox display="flex" alignItems="center">
                                <PPTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.2, mr: 0.5 }}>
                                    <Icon>people</Icon>
                                </PPTypography>
                                <PPTypography variant="button" color="text" fontWeight="medium">
                                    {t("shared")}&nbsp;
                                    <PPTypography variant="button" color="text" fontWeight="regular">
                                        ({note.createdByUser.firstName + ' ' + note.createdByUser.lastName})
                                    </PPTypography>
                                </PPTypography>
                            </PPBox>
                            :
                            <PPBox display="flex" alignItems="center">
                                <PPTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.2, mr: 0.5 }}>
                                    <Icon>person</Icon>
                                </PPTypography>
                                <PPTypography variant="button" color="text" fontWeight="medium">
                                    {t("privat")}&nbsp;
                                    <PPTypography variant="button" color="text" fontWeight="regular">
                                        ({note.createdByUser.firstName + ' ' + note.createdByUser.lastName})
                                    </PPTypography>
                                </PPTypography>
                            </PPBox>
                        }
                        <PPBox display="flex" alignItems="center">
                            <PPTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.2, mr: 0.5 }}>
                                <Icon>event</Icon>
                            </PPTypography>
                            <PPTypography variant="button" color="text" fontWeight="medium">
                                {moment(note.createdDate).format("DD.MM.YYYY")}
                            </PPTypography>
                        </PPBox>
                    </PPBox>
                </PPBox>
            </PPBox>
        </Grid>
    );
}

export default NoteCard;