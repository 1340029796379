import React, { useState, useEffect } from 'react';
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import { appEndPoints } from "appSettings";
import { getCurrentUser, getRequestOptions } from "controls/Helpers";
import DataTable from "controls/Tables/DataTable";
import SettingEditor from './SettingEditor';
import { useTranslation } from 'react-i18next';

function SettingsListing() {
    const { t } = useTranslation();
    const [pageState, setPageState] = useState({
        viewMode: "view",
        selectedSettingId: '',
        currentUser: getCurrentUser(),
        isLoading: false,
        data: [],
        rows: [],
        tabIndex: 0,
    });
    const [dialogEditorOpen, setDialogEditorOpen] = useState(false);

    const handleEditClick = (arg) => {
        setPageState(old => ({ ...old, selectedSettingId: arg }));
        setDialogEditorOpen(true);
    };

    const columns = [
        { Header: `${t("name")}`, accessor: "displayName", width: "25%", align: "left" },
        { Header: `${t("description")}`, accessor: "description", align: "left" },
        { Header: `${t("value")}`, accessor: "value", align: "center" },
        { Header: `${t("action")}`, accessor: "action", align: "center" },
    ];

    let rows =
        pageState.data.map((e, index) => (
            {
                displayName: (
                    <PPTypography variant="button" fontWeight="light" color="text">
                        {e.displayName}
                    </PPTypography>
                ),
                description: (
                    <PPTypography variant="button" fontWeight="light" color="text" style={{ display: 'block', whiteSpace: 'normal', maxWidth: '250px' }}>
                        {e.description}
                    </PPTypography>
                ),
                value: (
                    <PPTypography variant="button" fontWeight="light" color="text">
                        {e.value}
                    </PPTypography>
                ),
                action: (
                    <PPTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <PPButton variant="outlined" color="primary" size="small" onClick={() => handleEditClick(e.id)}>
                            <Icon>edit</Icon>&nbsp;{t("edit")}
                        </PPButton>
                    </PPTypography>
                ),
            }
        ));
    const handleCloseEditorDialog = () => {
        setDialogEditorOpen(false);
    };

    const handleRefreshListing = () => {
        fetchGridData();
    };

    function fetchGridData() {
        setPageState(old => ({ ...old, isLoading: true }));
        var fetchURL = appEndPoints.getSettingsEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(fetchURL, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                setPageState(old => ({ ...old, isLoading: false, data: resp, rows: resp }));
            })
            .catch(error => { console.log('error', error); setPageState(old => ({ ...old, isLoading: false })); })
    }

    useEffect(() => {
        fetchGridData();
    }, [])

    return (
        <PPBox>
            <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
                handleEditClick={handleEditClick}
            />
            <SettingEditor settingId={pageState.selectedSettingId} open={dialogEditorOpen} handleCloseDialog={handleCloseEditorDialog} handleRefreshListing={handleRefreshListing} />
        </PPBox>
    );
}

export default SettingsListing;
