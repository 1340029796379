import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PatientCard from "./PatientCard";
import CircularProgress from '@mui/material/CircularProgress';
import PPButton from "components/PPButton";
import moment from "moment";
import { getRequestOptions } from "controls/Helpers";
import NoRecords from "controls/NoRecords";
import { useTranslation } from 'react-i18next';

const PatientsListing = forwardRef((settings, ref) => {
    const { t } = useTranslation();
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        totalRecords: 0,
        currentPage: 1,
        pageSize: 21,
        searchQuery: settings.searchQuery,
        refreshListing: settings.refreshListing,
        handleSearch: settings.handleSearch,
    });

    useImperativeHandle(ref, () => ({
        refreshListing() {
            setPageState(old => ({ ...old, data: [], currentPage: 1, searchQuery: '' }), fetchGridData());
        },
        handleSearch(e) {
            setPageState(old => ({ ...old, searchQuery: e, data: [], currentPage: 1 }), fetchGridData(e));
        },
    }));

    const handleLoadMore = (e) => {
        e.preventDefault();
        setPageState(old => ({ ...old, currentPage: pageState.currentPage + 1 }))
    }

    function fetchGridData(q) {
        setPageState(old => ({ ...old, isLoading: true }));
        var searchKeyword = q !== null && q !== undefined ? q : pageState.searchQuery;
        var fetchURL = settings.fetchURL.replace("{pageNumber}", pageState.currentPage).replace("{pageSize}", pageState.pageSize) + "?q=" + searchKeyword;
        fetch(fetchURL, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                let currentData = resp.patients;
                if (pageState.currentPage > 1) {
                    currentData = pageState.data;
                    resp.patients.map((e, index) => (
                        currentData.push(e)
                    ))
                }
                //Todo: set totalRecords
                setPageState(old => ({ ...old, isLoading: false, data: currentData, totalRecords: resp.totalRecords }));
                // if (settings.handleCallback != null)
                //     settings.handleCallback(json.totalCount);
            })
            .catch(error => { console.log('error', error); setPageState(old => ({ ...old, isLoading: false })); })
    }

    useEffect(() => {
        fetchGridData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.currentPage, pageState.pageSize])

    return (
        <Grid container spacing={2}>
            {
                pageState.data.map((i, j) => (
                    <Grid item xs={12} md={6} xl={4} key={j}>
                        <PatientCard
                            id={i.id}
                            name={i.firstName + ' ' + i.lastName}
                            birthdate={i.birthdate ? moment(i.birthdate).format("DD.MM.YYYY") : null}
                            email={i.email}
                            primaryPhone={i.primaryPhone}
                            secondaryPhone={i.secondaryPhone}
                            photoFileName={i.photoFileName}
                            handlePatientDocumentationClick={settings.handlePatientDocumentationClick}
                            handlePatientEditClick={settings.handlePatientEditClick}
                            handlePatientDeleteClick={settings.handlePatientDeleteClick}
                        />
                    </Grid>
                ))
            }
            {
                (pageState.data === null || pageState.data.length === 0) && !pageState.isLoading ?
                    <NoRecords />
                    : null
            }
            {
                ((pageState.currentPage) * pageState.pageSize >= pageState.totalRecords) ?
                    null :
                    <PPBox style={{ width: "100%", paddingTop: "40px", paddingLeft: "15px", textAlign: "center" }}>
                        <PPButton variant="gradient" color="primary" type="button" onClick={handleLoadMore} disabled={pageState.isLoading}>
                            {pageState.isLoading ? <CircularProgress size={15} style={{ marginRight: "10px" }} /> : null}
                            {t("loadMore")}
                        </PPButton>
                    </PPBox>
            }
            {
                pageState.currentPage === 1 && pageState.isLoading ?
                    <div style={{ width: "100%", textAlign: "center", marginTop: "20px", paddingTop: "30px" }}>
                        <CircularProgress style={{ marginRight: "10px" }} />
                    </div>
                    : null
            }
        </Grid>
    )
});

export default PatientsListing