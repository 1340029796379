import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import { appEndPoints } from "appSettings";
import moment from 'moment';
import { getCurrentUser, getRequestOptions } from "controls/Helpers";
import Divider from "@mui/material/Divider";
import InlineEditor from "./InlineEditor";
import PPSnackbar from "components/PPSnackbar";
import Spinner from "controls/Spinner";
import { usePraxiProUIController, setProfilePhoto } from "context";
import { useTranslation } from 'react-i18next';

function BasicInfo() {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        imageTempUrl: '',
        imageUrl: '',
        photo: null,
        viewMode: "view",
        currentUser: getCurrentUser(),
        isLoading: false,
    });
    const [dispatch] = usePraxiProUIController();
    const closeErrorSB = () => setErrorSB(false);
    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleFileChange = (event) => {
        // Validate file extension
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = event.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (file.size > 2 * 1024 * 1024) {
                setErrorMessage(t("invalidPhotoSizeMsg"));
                setErrorSB(true);
            }
            else if (!allowedExtensions.includes(fileExtension)) {
                setErrorMessage(t("selectValidImageMsg"));
                setErrorSB(true);
            }
            else {
                setErrorSB(false);
                setPageState(x => ({ ...x, photo: file }));
            }
        }
    };

    const handleEditClick = () => {
        setPageState(old => ({ ...old, viewMode: "edit" }))
    };

    const handleCancelEdit = () => {
        setPageState(old => ({ ...old, viewMode: "view" }))
    };

    const handleProfileUpdate = (e) => {
        e.preventDefault();
        setOpenSpinnerPanel(true);
        const myForm = document.getElementById("updateProfile");
        const formData = new FormData(myForm);
        formData.append("id", pageState.currentUser.id ? pageState.currentUser.id : null);
        formData.append("firstName", pageState.firstName);
        formData.append("lastName", pageState.lastName);
        formData.append("birthdate", pageState.birthdate ? pageState.birthdate : null);
        formData.append("city", pageState.city ? pageState.city : '');
        formData.append("street", pageState.street ? pageState.street : '');
        formData.append("postalCode", pageState.postalCode ? pageState.postalCode : '');
        formData.append("socialNumber", pageState.socialNumber ? pageState.socialNumber : '');
        formData.append("taxNumber", pageState.taxNumber ? pageState.taxNumber : '');
        formData.append("photoFile", pageState.photo ? pageState.photo : null);
        var fetchURL = appEndPoints.putMyProfileEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('PUT', formData);
        fetch(fetchURL, requestOptions)
            .then(resp => resp.json())
            .then(resp => {
                let updatedUser = pageState.currentUser;
                // Check if updatedUser.address is null, and if so, initialize it
                if (!updatedUser.address) {
                    updatedUser.address = {};
                }
                updatedUser.firstName = formData.get('firstName');
                updatedUser.lastName = formData.get('lastName');
                updatedUser.birthdate = formData.get('birthdate') ? moment(formData.get('birthdate'), "yyyy-MM-DD").format("DD.MM.YYYY") : null;
                updatedUser.address.city = formData.get('city');
                updatedUser.address.street = formData.get('street');
                updatedUser.address.postalCode = formData.get('postalCode');
                updatedUser.socialNumber = formData.get('socialNumber');
                updatedUser.taxNumber = formData.get('taxNumber');
                updatedUser.version = moment().format('DDMMYYYYHHmmss');
                updatedUser.photoFileName = resp.photoFileName;
                window.localStorage.setItem('currentUser', JSON.stringify(updatedUser));
                setPageState(old => ({ ...old, viewMode: "view", isLoading: false }));
                fetchURL = appEndPoints.getFileBinary.replace("{hostname}", appEndPoints.hostname).replace("{id}", updatedUser.id).replace("{fileName}", resp.photoFileName);
                fetch(fetchURL, getRequestOptions())
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Error occured');
                        }
                        return response.blob();
                    })
                    .then(blob => {
                        setProfilePhoto(dispatch, URL.createObjectURL(blob));
                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });
                setOpenSpinnerPanel(false);
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    function fetchUserDetails() {
        setPageState(x => ({ ...x, id: '', imageUrl: '' }));
        if (pageState.currentUser.photoFileName) {
            var fetchURL = appEndPoints.getFileBinary.replace("{hostname}", appEndPoints.hostname).replace("{id}", pageState.currentUser.id).replace("{fileName}", pageState.currentUser.photoFileName);
            fetch(fetchURL, getRequestOptions())
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error occured');
                    }
                    return response.blob();
                })
                .then(blob => {
                    setPageState(x => ({ ...x, imageUrl: URL.createObjectURL(blob) }));
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                });
        }
    }

    useEffect(() => {
        if (pageState.photo) {
            setPageState(x => ({ ...x, imageTempUrl: URL.createObjectURL(pageState.photo) }));
        }
    }, [pageState.photo]);

    useEffect(() => {
        fetchUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PPBox p={2}>
            <PPBox display="flex" justifyContent="space-between" alignItems="center">
                <PPTypography variant="h5" fontWeight="medium" style={{ textTransform: "uppercase" }}>
                    {t("myProfile")}
                </PPTypography>
                <PPButton color="primary" onClick={handleEditClick} disabled={pageState.viewMode === "edit" ? true : false}>
                    <Icon>edit</Icon>&nbsp;
                    {t("editProfile")}
                </PPButton>
            </PPBox>
            <PPBox>
                <PPBox opacity={0.3}>
                    <Divider />
                </PPBox>
                <PPBox>
                    <form id="updateProfile" method="POST" encType="multipart/form-data" name="profileForm" onSubmit={handleProfileUpdate}>
                        <PPBox mb={2}>
                            <input
                                accept=".jpg,.png,.jpeg"
                                type="file"
                                id="select-image"
                                name="photo"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                            />
                            <div className="profile-photo-container">
                                {(pageState.imageUrl !== null && pageState.imageUrl !== '') || (pageState.imageTempUrl !== null && pageState.imageTempUrl !== '') ? (
                                    <img src={pageState.imageTempUrl ? pageState.imageTempUrl : pageState.imageUrl} alt="profile" className="profile-photo" />
                                ) : <div className="upload-photo-placeholder">{t("uploadProfilePhoto")}</div>}
                            </div>
                            {
                                pageState.viewMode === "edit" ? <label htmlFor="select-image">
                                    <PPButton variant="gradient" color="primary" component="span">
                                        <Icon>save</Icon>&nbsp;{t("uploadPhoto")}
                                    </PPButton>
                                </label> : null
                            }
                            <PPSnackbar
                                color="error"
                                icon="warning"
                                title="Error"
                                content={errorMessage}
                                dateTime=""
                                open={errorSB}
                                onClose={closeErrorSB}
                                close={closeErrorSB}
                                bgWhite
                            />
                        </PPBox>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} lg={4}>
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="bold">
                                        {t("email")}
                                    </PPTypography>
                                </PPBox>
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="regular" color="text">
                                        {pageState.currentUser.email}
                                    </PPTypography>
                                </PPBox>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} lg={4}>
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="bold">
                                        {t("firstName")} *
                                    </PPTypography>
                                </PPBox>
                                <InlineEditor viewMode={pageState.viewMode} type="text" fieldName={"firstName"} fieldValue={pageState.currentUser.firstName} isRequired={true} />
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="bold">
                                        {t("city")}
                                    </PPTypography>
                                </PPBox>
                                <InlineEditor viewMode={pageState.viewMode} type="text" fieldName={"city"} fieldValue={pageState.currentUser.address?.city} />
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="bold">
                                        {t("socialSecurityNumber")}
                                    </PPTypography>
                                </PPBox>
                                <InlineEditor viewMode={pageState.viewMode} type="text" fieldName={"socialNumber"} fieldValue={pageState.currentUser.socialNumber} />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="bold">
                                        {t("lastName")} *
                                    </PPTypography>
                                </PPBox>
                                <InlineEditor viewMode={pageState.viewMode} type="text" fieldName={"lastName"} fieldValue={pageState.currentUser.lastName} isRequired={true} />
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="bold">
                                        {t("street")}
                                    </PPTypography>
                                </PPBox>
                                <InlineEditor viewMode={pageState.viewMode} type="text" fieldName={"street"} fieldValue={pageState.currentUser.address?.street} />
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="bold">
                                        {t("tin")}
                                    </PPTypography>
                                </PPBox>
                                <InlineEditor viewMode={pageState.viewMode} type="text" fieldName={"taxNumber"} fieldValue={pageState.currentUser.taxNumber} />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="bold">
                                        {t("dateOfBirth")}
                                    </PPTypography>
                                </PPBox>
                                <InlineEditor viewMode={pageState.viewMode} type="date" fieldName={"birthdate"} fieldValue={pageState.currentUser.birthdate} isRequired={false} />
                                <PPBox display="flex" py={1} pr={2}>
                                    <PPTypography variant="button" fontWeight="bold">
                                        {t("postalCode")}
                                    </PPTypography>
                                </PPBox>
                                <InlineEditor viewMode={pageState.viewMode} type="text" fieldName={"postalCode"} fieldValue={pageState.currentUser.address?.postalCode} />
                            </Grid>
                            {
                                pageState.viewMode === "edit" ?
                                    <PPBox style={{ width: "100%" }}>
                                        <div style={{ float: "right", paddingLeft: "10px" }}>
                                            <PPButton color="primary" type="submit" form="updateProfile" fullWidth>
                                                <Icon>save</Icon>&nbsp;{t("save")}
                                            </PPButton>
                                        </div>
                                        <div style={{ float: "right", paddingRight: "10px" }}>
                                            <PPButton variant="gradient" color="light" onClick={handleCancelEdit} fullWidth>
                                                <Icon>cancel</Icon>&nbsp;{t("cancel")}
                                            </PPButton>
                                        </div>
                                    </PPBox>
                                    : null
                            }
                        </Grid>
                    </form>
                </PPBox>
            </PPBox>
            <Spinner open={openSpinnerPanel} />
        </PPBox>
    );
}

export default BasicInfo;
