import React, { useState, useEffect } from 'react';
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { appEndPoints } from "appSettings";
import { getCurrentUser, getRequestOptions } from "controls/Helpers";
import TemplateEditor from './TemplateEditor';
import TemplateCard from './TemplateCard';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Spinner from "controls/Spinner";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';

const TemplatesListing = ({ open, handleRefreshListing, handleCloseDialog }) => {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        viewMode: "view",
        selectedTemplateId: '',
        currentUser: getCurrentUser(),
        data: [],
    });
    const [dialogEditorOpen, setDialogEditorOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleTemplateEditClick = (arg) => {
        setPageState(old => ({ ...old, selectedTemplateId: arg }));
        setDialogEditorOpen(true);
    };

    const handleOpenEditorDialog = () => {
        setPageState(old => ({ ...old, selectedTemplateId: '' }));
        setDialogEditorOpen(true);
    };

    const handleRefreshTemplatesListing = () => {
        setDialogEditorOpen(false);
        handleRefreshListing();
        fetchGridData();
    };

    const handlBackClick = (e) => {
        e.preventDefault();
        setDialogEditorOpen(false);
        setPageState(x => ({ ...x, selectedTemplateId: '' }));
    };

    const handleTemplateDeleteClick = (arg) => {
        setPageState(old => ({ ...old, selectedTemplateId: arg }));
        setConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        setOpenSpinnerPanel(true);
        var body = JSON.stringify({
            "id": pageState.selectedTemplateId,
        });
        fetch(appEndPoints.templateEndpoint.replace("{hostname}", appEndPoints.hostname), getRequestOptions('DELETE', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                handleRefreshTemplatesListing();
                setOpenSpinnerPanel(false);
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    function fetchGridData() {
        setOpenSpinnerPanel(true);
        fetch(appEndPoints.templateEndpoint.replace("{hostname}", appEndPoints.hostname), getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                setPageState(old => ({ ...old, data: resp }));
                setOpenSpinnerPanel(false);
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    }

    useEffect(() => {
        fetchGridData();
    }, [])

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseDialog();
            }
        }} aria-labelledby="responsive-dialog-title" className='templatesListing'>
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>content_copy</Icon>&nbsp;Vorlagen</div>
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <PPBox>
                    {!dialogEditorOpen ?
                        pageState.data.map((i, j) => (
                            <TemplateCard template={i} key={j}
                                handleTemplateEditClick={handleTemplateEditClick}
                                handleTemplateDeleteClick={handleTemplateDeleteClick}
                            />
                        )) : null
                    }
                    {
                        dialogEditorOpen ? <TemplateEditor templateId={pageState.selectedTemplateId} handleRefreshListing={handleRefreshTemplatesListing} /> : null
                    }
                </PPBox>
            </DialogContent>
            <DialogActions>
                <Divider />
                {
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={4}>
                            <PPButton variant="gradient" color="light" onClick={handleCloseDialog} fullWidth>
                                <Icon>cancel</Icon>&nbsp;{t("cancel")}
                            </PPButton>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {
                                dialogEditorOpen ? <PPButton variant="gradient" color="light" onClick={handlBackClick} fullWidth>
                                    <Icon>arrow_back</Icon>&nbsp;{t("back")}
                                </PPButton> :
                                    null
                            }
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {
                                dialogEditorOpen ? <PPButton variant="gradient" color="primary" type="submit" form="formManageTemplate" fullWidth>
                                    <Icon>save</Icon>&nbsp;{t("save")}
                                </PPButton> :
                                    <PPButton color="primary" onClick={handleOpenEditorDialog} fullWidth>
                                        <Icon>add_circle</Icon>&nbsp;
                                        {t("add")}
                                    </PPButton>
                            }
                        </Grid>
                    </Grid>
                }
            </DialogActions>
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                message={t("confirmDeleteTemplateMsg")}
            />
            <Spinner open={openSpinnerPanel} />
        </Dialog>
    );
}

export default TemplatesListing;
